import { useEffect, useState } from 'react';

const getWindowDimensions=()=> {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowSize= ()=>  {
  const [windowSize, setWindowSize] = useState(getWindowDimensions());

  const handleSize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);


  return windowSize;
};

export default useWindowSize;