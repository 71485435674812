import styled from 'styled-components';
import { TableCell } from '@mui/material';

export const StyledCommentCell = styled(TableCell)`
  font: normal normal normal 16px PT Sans;
  vertical-align: top;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.33);
  margin-bottom: 20px;
  cursor: pointer;

  &:hover, :focus {
    border: 1px solid black;
    background-color: rgba(25, 118, 210, 0.04);
    border-radius: 5px;
    outline: 1px solid black;

    .hearing-comment, .hearing-list-item {
      background-color: rgba(25, 118, 210, 0.04);
    }
  }

  @media (max-width: 1200px) {
    gap: 5px;
  }

  .commentList {
    margin-top: 0;
    width: 80%;
    pointer-events: none;
    
    @media (max-width: 1200px) {
      width: 60%;
    }
  }

  .hearing-comment {
    margin-bottom: 0;
  }

  .hearing-comment__reply-link,
  .hearing-comment-actions,
  .hearing-comment__map {
    visibility: hidden;
  }
  
  .hearing-comment-user{
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }

  .hearing-comment__edit-links,
  .sub-comments,
  .activeJoinRequestNotifications{
    display: none;
  }

  .hearing-comment-images-image {
    pointer-events: auto;
  }

  .hearing-comment__actions-bar {
    justify-content: end;
  }

  .hearing-list-item-title-wrap {
    display: flex;
    justify-content: space-between;
  }

  .hearing-list-item {
    margin-bottom: 0;
    pointer-events: none;
    width: 20%;
    @media (max-width: 1200px) {
      width: 40%;
    }
  }

  .hearing-list-item-image {
    display: none;
  }

  .hearing-list-item-title {
    font-size: 16px;
  }

  .hearing-list-item-content {
    padding: 12px;
    width: 100%;
    max-width: 100%;
  }

  .label {
    font-size: 12px;
  }

  .hearing-list-item-times {
    font-size: 12px;
    margin-bottom: 6px;
  }

`;

export const TableWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font: normal normal normal 16px PT Sans;
`;
