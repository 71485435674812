import { DateTime } from 'luxon';
import getMessage from '../../utils/getMessage';
import { FormattedMessage } from 'react-intl';
import { IconButton } from '@mui/material';
import chatbubble from '../../../assets/images/chatbubble.svg';
import calendarfilled from '../../../assets/images/calendarfilled.svg';
import hearingJoinRequestSvg from '../../../assets/images/hearing-join-request.svg';
import { StyledBadge } from './HearingCard';

const HearingCardInformation = ({hearing, history}) => {

  const openingDateTime = DateTime.fromISO(hearing.open_at)
  const closingDateTime = DateTime.fromISO(hearing.close_at)
  const openingTimeFormat = (openingDateTime.year === closingDateTime.year) ? 'dd.MM' : 'dd.MM.yyyy'
  const openingTime = openingDateTime.toFormat(openingTimeFormat+' HH:mm');
  const closingTime = closingDateTime.toFormat('dd.MM.yyyy HH:mm');

  const now = Date.now();
  const nowIsBetweenTimes = now > openingDateTime.toMillis() && now < closingDateTime.toMillis();
  let hearingState = 'hearingClosed';
  if (hearing.published && nowIsBetweenTimes) {
    hearingState = 'published';
  } else if (!hearing.published) {
    hearingState = 'draft'
  }

  return (
    <div className="hearing-card-comments">
      <div>
        <div style={{display: 'grid', gridTemplateColumns: 'min-content 1fr', alignItems: 'center'}}>
          <img
            className="hearing-image"
            src={chatbubble}
            style={{ color: '#000000' }}
            alt={getMessage('commentCount')}
          />
          <span>&nbsp;{hearing.n_comments}</span>
        </div>
        </div>
        {hearing.can_access_detail && hearing.active_join_requests_num > 0 &&
          <IconButton
            aria-label="active join requests notifications"
            style={{ marginLeft: '20px' }}
            onClick={() => history.push({
              pathname: `/${hearing.slug}/manage/hearing-join-requests`,
            })}
          >
            <StyledBadge badgeContent={hearing.active_join_requests_num} color="warning" max={99}>
              <img
                className="hearing-image"
                src={hearingJoinRequestSvg}
              />
            </StyledBadge>
          </IconButton>
        }
      <div className="hearing-card-user-info">
        
        {/* TODO: Possibly add later the info about how many persons there are in the hearing */}
        {/* <img className="hearing-image" src={person} style={{ width: '20px', color: '#7E23E7' }}/> */}
        
      </div>
      <div>
        <div className="hearing-card-date">
          <img
            className="hearing-image"
            src={calendarfilled}
            style={{ color: '#7E23E7' }}
            alt={getMessage('hearingSchedule')}
          />
          <div><span className='dateSpan'>{openingTime}</span> <span className='dateSpan'>&nbsp;-&nbsp;{closingTime}</span></div>
        </div>
        <div>
          <div className={`hearing-state ${hearingState}`}>{getMessage(hearingState)}</div>
        </div>
      </div>
      
      <span className="sr-only">
        {hearing.n_comments === 1 ? (
          <FormattedMessage id="hearingCardComment" />
        ) : (
          <FormattedMessage id="hearingCardComments" />
        )}
      </span>
    </div>
  );

}

export default HearingCardInformation;