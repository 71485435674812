import { useEffect, useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Panel, PanelGroup, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import getMessage from '../../utils/getMessage';
import Icon from '../../utils/Icon';
import {addCookieScript, removeCookieScript, changeCookieConsent} from "../../utils/cookieUtils";
import Link from '../LinkWithLang';

const CookieManagementModal = (props) => {
  
  const [expanded, setExpanded] = useState(false);
  const [activeKey, setActiveKey] = useState('0');
  const [changesMade, setChangesMade] = useState(false); // <- Was set true inside code that was removed. Now useless? 
  const [cookies, setCookies] = useState({googleAnalytics: false});

  const cookieConsentCheck = () => {
    if (document.cookie.split('; ').find(row => row.startsWith('CookieConsent'))) {
      const consentValue = document.cookie.split('; ').find(row => row.startsWith('CookieConsent')).split('=')[1];
      if (consentValue === 'true') {
        const prevCookies = cookies;
        setCookies({
          ...prevCookies,
          googleAnalytics: true
        });
        setChangesMade(false);
      } else {
        const prevCookies = cookies;
        setCookies({
          ...prevCookies,
          googleAnalytics: false
        });
        setChangesMade(false);
      }
    }
  }

  useEffect(() => {
    cookieConsentCheck();
  }, [props])
  
  const handleSelect = (activeKey) => {
    setActiveKey(activeKey);
  }

  const handleKeyDown = (ev, _key, _value) => {
    if (ev && ev.key === "Enter") {
      console.debug('Is [key] used?')
    }
  }

  const handleOnHide = () => {
    if (changesMade) {
      if (cookies.googleAnalytics) {
        addCookieScript();
      } else {
        removeCookieScript();
      }
      changeCookieConsent();
    }
    props.close();
  }

  return (
    <Modal
      className="cookie-management-modal"
      size="xl"
      show={props.isOpen}
      onHide={() => handleOnHide()}
    >
      <Modal.Body>
        <Button id="cookie-management-modal-close" onClick={() => props.close()}>
          <Icon name="close" />
        </Button>
        <Modal.Title><FormattedMessage id="cookieBar.modal.header" /></Modal.Title>
        <Panel id="collapsible-cookie-panel" expanded={expanded} onToggle={()=>{}}>
          <p>{getMessage('cookieBar.modal.paragraph1')}</p>
          <Panel.Collapse>
            <p>{getMessage('cookieBar.modal.paragraph2')}</p>
            <FormattedMessage
              id="cookieBar.modal.cookieInfoLink"
              values={{
                linkToInfo: (
                  <Link to={{ path: "/privacy" }} target="_blank">
                    {getMessage('footerPrivacy')}
                  </Link>
                ),
              }}
            />
          </Panel.Collapse>
        </Panel>
        <a
          id="cookie-show-more"
          tabIndex="0"
          role="button"
          onClick={() => setExpanded(!expanded)}
          onKeyDown={(ev) => handleKeyDown(ev, 'expanded', !expanded)}
        >
          {expanded ? getMessage('cookieBar.modal.showLess') : getMessage('cookieBar.modal.showMore')}
        </a>
        <PanelGroup
          accordion
          id="cookie-management-panel"
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Panel eventKey="1">
            <Panel.Heading>
              <Panel.Title>
                <div id="cookie-panel-title-container">
                  <div
                    onClick={() =>
                      setActiveKey( activeKey === '1' ? '0' : '1' )
                    }
                    onKeyDown={
                      (ev) =>
                        handleKeyDown(ev, 'activeKey', activeKey === '1' ? '0' : '1')
                    }
                  >
                    <Icon
                      id="cookie-panel-icon"
                      name={activeKey === '1' ? 'chevron-down' : 'chevron-right'}
                    />
                    <a tabIndex="0" role="tab">
                      {getMessage('cookieBar.modal.panel.functionalCookies')}
                    </a>
                  </div>
                  <span id="cookie-panel-switch-container">
                    <span id="cookie-panel-info-status" style={{ marginRight: 0 }}>
                      {getMessage('cookieBar.modal.panel.alwaysEnabled')}
                    </span>
                  </span>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible id="cookie-panel-body">
              {getMessage('cookieBar.modal.panel.functionalCookiesContent')}
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </Modal.Body>
    </Modal>
  );
}

CookieManagementModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func
};

export default CookieManagementModal;
