import {createAction} from 'redux-actions';
import {get} from 'lodash';
import config from '../../config';


export function getCurrentUser() {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) return null;
    dispatch(createAction('fetchUserData')());
    const result = await fetch(`${config.apiBaseUrl}/v1/auth/user/`, {
      method: "GET",
      headers: {
        Authorization: "Token " + token,
      }
    });
    const user = await result.json()
    const userWithOrganization = Object.assign({},
      {displayName: get(user, 'first_name') + ' ' + get(user, 'last_name')},
      {nickname: get(user, 'nickname')},
      {username: get(user, 'username')},
      {firstname: get(user, 'first_name')},
      {followedHearings: get(user, 'followed_hearings')},
      {votedSectionComments: get(user, 'voted_section_comments')},
      {facilitatorHearings: get(user, 'facilitator_hearings')},
      {membersHearings: get(user, 'members_hearings')},
      {lastname: get(user, 'last_name')},
      {answered_questions: get(user, 'answered_questions')},
      {email: get(user, 'email')},
      {adminOrganizations: get(user, 'admin_organizations', null)},
      {hasStrongAuth: false},
      {oid: get(user, 'oid', null)},
      {missing_information: get(user, 'missing_information', null)}
    );
    return dispatch(createAction('receiveUserData')(userWithOrganization));
  }
}