import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import getMessage from '../../utils/getMessage';
import {addCookieScript} from "../../utils/cookieUtils";
import CookieManagementModal from "./CookieManagementModal";

function CookieBar() {
  const [showCookieManagementModal, setShowCookieManagementModal] = useState(false);
  
  const openCookieManagementModal = () => {
    setShowCookieManagementModal(true);
  };

  const closeCookieManagementModal = () => {
    setShowCookieManagementModal(false);
  };

  const handleKeyDown = (ev) => {
    if (ev && ev.key === "Enter") {
      openCookieManagementModal();
    }
  };

  return (
    <React.Fragment>
        <CookieConsent
          buttonId="cookie-accept-button"
          buttonText={getMessage('cookieBar.accept')}
          contentClasses="cookie-content"
          buttonWrapperClasses="cookie-buttons"
          declineButtonId="cookie-decline-button"
          declineButtonText={getMessage('cookieBar.decline')}
          disableStyles
          enableDeclineButton
          setDeclineCookie
          expires={90}
          onAccept={addCookieScript}
          flipButtons
        >
          <h3 id="cookiebar-title">{getMessage('cookieBar.title')}</h3>
          <p id="cookiebar-content">{getMessage('cookieBar.description')}</p>

        </CookieConsent>
        <CookieManagementModal
          isOpen={showCookieManagementModal}
          close={closeCookieManagementModal}
        />
      </React.Fragment>
  )

}

export default CookieBar;
