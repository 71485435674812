import pluginMap from './shared_config.json';

const config = {
  pluginMap,
  languages: ['fi', 'sv'],
  activeLanguage: 'fi',
  publicUrl: (typeof window !== 'undefined' ? window.PUBLIC_URL : null) || 'http://localhost:8086/',
  apiBaseUrl: (typeof window !== 'undefined' ? window.API_BASE_URL : null) || 'http://localhost:8000/',
  uiConfig: typeof window !== 'undefined' ? window.UI_CONFIG : null,
  openIdClientId: typeof window !== 'undefined' ? window.OPENID_CLIENT_ID : null,
  openIdAudience: typeof window !== 'undefined' ? window.OPENID_AUDIENCE : null,
  openIdAuthority: typeof window !== 'undefined' ? window.OPENID_AUTHORITY : null,
  openIdApiTokenUrl: typeof window !== 'undefined' ? window.OPENID_APITOKEN_URL : null,
  //heroImageURL: (typeof window !== 'undefined' ? window.HERO_IMAGE_URL : null)
  //  || '/assets/images/banner_xl.png',
  showAccessibilityInfo: typeof window !== 'undefined' ? window.SHOW_ACCESSIBILITY_INFO : false,
  showSocialMediaSharing: typeof window !== 'undefined' ? window.SHOW_SOCIAL_MEDIA_SHARING : true,
  enableHighContrast: typeof window !== 'undefined' ? window.ENABLE_HIGHCONTRAST : false,
  showCookiebar: typeof window !== 'undefined' ? window.SHOW_COOKIEBAR : false,
  enableMPASSid: typeof window !== 'undefined' ? window.ENABLE_MPASSID : false,
};

export default config;
