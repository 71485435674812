import styled from 'styled-components';
import { getUser } from '../../store/selectors/user';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const StyledSpan = styled.span`

    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    background-color: darkgrey;
    color: black;
    font: normal normal normal 60px PT Sans;
    border-radius: 50%;
    font-weight: 800;
`;

const ProfileImage = ({ user }) => {
  const firstNameInitial = user.firstname ? user.firstname[0] : user.nickname[0];
  const lastNameInitial = user.lastname ? user.lastname[0] : user.username[0];

  return (
    <StyledSpan>
      {!!firstNameInitial && firstNameInitial}
      {!!lastNameInitial && lastNameInitial}
    </StyledSpan>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

ProfileImage.propTypes = {
  user: PropTypes.object,
};
export default connect(mapStateToProps)(ProfileImage);