import DigiraatiButton from '../../common/DigiraatiButton';
import React from 'react';

const DigiraatiTabButton = ({ tabLabel, handleChange, isSelected, value, style }) => {
  const color = '#ffc300';
  return (
    <DigiraatiButton
      role='tab'
      className={isSelected ? 'selected' : ''}
      onClick={event => handleChange(event, value)}
      buttonLabel={tabLabel}
      style={{
        width: '300px',
        borderRadius: '5px',
        '&.selected': {
          backgroundColor: color,
          color: 'black',
          ':hover': {
            backgroundColor: color,
            color: 'black',
            boxShadow: 'none',
          },
          ':focus': {
            border: `1px solid ${color}`,
          },
        },
        ':focus': {
          border: '3px solid black',
        },
        ...style,
      }}
    />
  );
};

export default DigiraatiTabButton;