/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Alert, Button, Checkbox, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';
import Icon from '../utils/Icon';
import { getImageAsBase64Promise } from '../utils/hearing';
import { localizedNotifyError } from '../utils/notify';
import { getSectionCommentingErrorMessage, isSectionCommentingMapEnabled } from '../utils/section';
import { Polygon } from 'react-leaflet';
// eslint-disable-next-line import/no-unresolved
import urls from '@city-assets/urls.json';
// eslint-disable-next-line import/no-unresolved
import localization from '@city-i18n/localization.json';
import leafletMarkerIconUrl from '../../assets/images/leaflet/marker-icon.png';
import { getCorrectContrastMapTileUrl } from '../utils/map';
import Leaflet, { LatLng } from 'leaflet';
import leafletMarkerShadowUrl from '../../assets/images/leaflet/marker-shadow.png';
import leafletMarkerRetinaIconUrl from '../../assets/images/leaflet/marker-icon-2x.png';
import CommentFormMap from './CommentFormMap/CommentFormMap';
import DigiraatiEmojiInput from './EmojiInput';
import Sticker from './Hearing/sortable-comment-list/comment-list/comment/Sticker';
import getMessage from '../utils/getMessage';


Leaflet.Marker.prototype.options.icon = new Leaflet.Icon({
  iconUrl: leafletMarkerIconUrl,
  shadowUrl: leafletMarkerShadowUrl,
  iconRetinaUrl: leafletMarkerRetinaIconUrl,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
});

export class BaseCommentForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.id = Math.random().toString(36).slice(2, 7);
    this.time = null;
    this.state = {
      collapsed: true,
      commentText: '',
      nickname: props.defaultNickname || '',
      imageTooBig: false,
      images: [],
      pinned: false,
      showAlert: true,
      hideName: false,
      geojson: {},
      mapCommentText: '',
      sticker: null,
    };
    this.getSelectedImagesAsArray = this.getSelectedImagesAsArray.bind(this);
  }

  componentDidMount = () => {
    if (this.isUserAdmin()) {
      this.setState({ nickname: this.props.defaultNickname });
    }
    if (!!this.props.editedText && this.props.editedText !== this.state.commentText) {
      this.setState({ commentText: this.props.editedText });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.collapseForm && nextProps.collapseForm) {
      this.clearCommentText();
      this.toggle();
    }
  }

  toggle() {
    const { canComment, section } = this.props;
    if (canComment) {
      this.props.cancelEditing();
      this.setState({
        collapsed: !this.state.collapsed,
        commentText: '',
        nickname: this.props.defaultNickname || '',
        imageTooBig: false,
        images: [],
        pinned: false,
        showAlert: true,
        hideName: false,
        mapCommentText: '',
        sticker: null,
      });
      if (this.props.onOverrideCollapse instanceof Function) {
        this.props.onOverrideCollapse();
      }
    } else {
      localizedNotifyError(getSectionCommentingErrorMessage(section));
    }
  }

  handleTextChange(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ commentText: event.target.value });
    if (this.props.hasDelayToSaveType) {
      const COMMENT_POLL_INTERVAL_IN_MS = 1000;
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        if (this.state.commentText !== this.props.editedText) {
          this.props.setEditing(this.state.commentText);
        }
      }, COMMENT_POLL_INTERVAL_IN_MS);
    } else {
      if (event.target.value !== this.props.editedText) {
        this.props.setEditing(event.target.value);
      }
    }
  }

  handleNicknameChange(event) {
    this.setState({ nickname: event.target.value });
  }

  clearCommentText() {
    this.setState({ commentText: '' });
  }

  submitComment() {
    const pluginComment = this.getPluginComment();
    let pluginData = this.getPluginData();
    let nickname =
      this.state.nickname === ''
        ? this.props.nicknamePlaceholder
        : this.state.nickname;
    let commentText =
      this.state.commentText === null ? '' : this.state.commentText;
    let geojson = this.state.geojson;
    let label = null;
    let images = this.state.images;
    let pinned = this.state.pinned;
    let mapCommentText = this.state.mapCommentText;
    let sticker = this.state.sticker;

    // plugin comment will override comment fields, if provided
    if (pluginComment) {
      commentText = pluginComment.content || commentText;
      nickname = pluginComment.author_name || nickname;
      pluginData = pluginComment.plugin_data || pluginData;
      label = pluginComment.label || null;
      images = pluginComment.image ? [pluginComment.image] : images;
      geojson = pluginComment.geojson || geojson;
      pinned = pluginComment.pinned || null;
      mapCommentText = pluginComment.mapCommentText || mapCommentText;
      sticker = pluginComment.sticker || sticker;
    } else if (pluginData && typeof pluginData !== 'string') {
      // this is for old-fashioned plugins with only data
      pluginData = JSON.stringify(pluginData);
    }
    this.props.onPostComment(
      commentText,
      nickname,
      pluginData,
      geojson,
      label,
      images,
      pinned,
      mapCommentText,
      sticker,
    );
    this.setState({
      collapsed: false,
      commentText: '',
      nickname: this.props.defaultNickname || '',
      imageTooBig: false,
      images: [],
      pinned: false,
      showAlert: true,
      hideName: false,
      geojson: {},
      mapCommentText: '',
      sticker: null,
    });
  }

  handleChange(event) {
    const imagePromisesArray = [];
    const images = [];
    this.isImageTooBig(event.target.files);

    for (let _i = 0; _i < this.refs.images.files.length; _i += 1) {
      imagePromisesArray.push(
        getImageAsBase64Promise(this.refs.images.files[_i]),
      );
    }

    Promise.all(imagePromisesArray).then(arrayOfResults => {
      for (let _i = 0; _i < this.refs.images.files.length; _i += 1) {
        const imageObject = { title: 'Title', caption: 'Caption' };

        imageObject.image = arrayOfResults[_i];
        images.push(imageObject);
      }

      this.setState({ images });
    });
  }

  /**
   * Determines whether the logged in user is admin or not.
   * The array in users with key adminOrganizations should be of length > 0
   */
  isUserAdmin = () =>
    this.props.user &&
    Array.isArray(this.props.user.adminOrganizations) &&
    this.props.user.adminOrganizations.length > 0;

  // eslint-disable-next-line class-methods-use-this
  getPluginData() {
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getPluginComment() {
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getSelectedImagesAsArray(files) {
    const imagesArray = [];
    for (let _i = 0; _i < files.length; _i += 1) {
      imagesArray.push(files[_i]);
    }
    return imagesArray;
  }

  /**
   * When user type is admin, an alert is shown, use this method to close the alert.
   */
  handleCloseAlert = () => {
    this.setState(prevState => ({
      showAlert: !prevState.showAlert,
    }));
  };

  /**
   * When logged in as admin, user may chose to hide their identity.
   */
  handleToggleHideName = () => {
    this.setState(prevState => ({
      nickname: !prevState.hideName
        ? getMessage('employee')
        : this.props.defaultNickname,
      hideName: !prevState.hideName,
    }));
  };

  /**
   * Toggle the pinning of comment
   */
  handleTogglePin = () => {
    this.setState(prevState => ({
      pinned: !prevState.pinned,
    }));
  };

  isImageTooBig(images) {
    // eslint-disable-line class-methods-use-this
    let isImageTooBig = false;
    Array.from(images).forEach(image => {
      if (image.size > 1000000) {
        isImageTooBig = true;
      }
    });
    if (isImageTooBig) {
      this.setState({ imageTooBig: true });
    } else {
      this.setState({ imageTooBig: false });
    }
  }

  /**
   * When admin user is posting a comment, we will show a closeable warning.
   */
  renderAdminWarning = () => (
    <Alert bsStyle="warning">
      <div className="comment-form__comment-alert">
        <div className="comment-form__comment-alert__alert-icon">
          <Icon name="info-circle" size="lg" />
        </div>
        <span className="comment-form__comment-alert__alert-message">
          <FormattedMessage id="adminCommentMessage" />
        </span>
        <div className="comment-form__comment-alert__alert-close">
          <Icon name="close" onClick={this.handleCloseAlert} />
        </div>
      </div>
    </Alert>
  );

  /**
   * Render the checkbox to hide user name and identitiy for admin user.
   */
  renderHideNameOption = () => (
    <Checkbox
      checked={this.state.hideName}
      key={uuid()}
      onChange={this.handleToggleHideName}
    >
      <FormattedMessage id="hideName" />
    </Checkbox>
  );

  /**
   * For admins, there is slightly different form.
   */
  renderFormForAdmin = () => {
    const { user } = this.props;
    const organization = this.isUserAdmin() && user.adminOrganizations[0];
    return (
      <FormGroup>
        <FormControl
          type="text"
          placeholder={this.props.nicknamePlaceholder}
          value={this.state.nickname}
          onChange={this.handleNicknameChange.bind(this)}
          maxLength={32}
          disabled
        />
        <FormControl
          type="text"
          placeholder={getMessage('organization')}
          value={organization || ''}
          onChange={() => {
          }}
          maxLength={32}
          disabled
        />
      </FormGroup>
    );
  };

  /**
   * If an admin type of user is posting comment, the form is slightly different.
   * @returns {JSX<Component>}
   */
  renderNameFormForUser = () => {
    if (this.props.hideNameForm) {
      return <></>;
    }
    const isAdminUser = this.isUserAdmin();
    const headingId = isAdminUser ? 'nameAndOrganization' : 'nickname';

    return (
      <React.Fragment>
        <label htmlFor="commentNickname" className="h4">
          <FormattedMessage id={headingId} />
        </label>
        {isAdminUser && this.state.showAlert && this.renderAdminWarning()}
        {isAdminUser && this.renderHideNameOption()}
        {isAdminUser ? (
          <div className="comment-form__group-admin">
            {this.renderFormForAdmin()}
          </div>
        ) : (
          <FormGroup>
            <FormControl
              id={this.getId('commentNickname')}
              type="text"
              placeholder={this.props.nicknamePlaceholder}
              value={this.state.nickname}
              onChange={this.handleNicknameChange.bind(this)}
              maxLength={32}
            />
          </FormGroup>
        )}
      </React.Fragment>
    );
  };

  /**
   * When user is of admin type, they are allowed to pin a comment to top.
   * In the form, an icon can be shown to pin or unpin the comment.
   */
  renderPinUnpinIcon = () => (
    <Button
      className={classnames([
        'comment-form__heading-container__pin__icon',
        {
          'comment-form__heading-container__pin__pin-comment': !this.state
            .pinned,
          'comment-form__heading-container__pin__unpin-comment': this.state
            .pinned,
        },
      ])}
      style={{
        backgroundImage: !this.state.pinned? `url(/assets/images/icon-metro-pin.svg)`:`url(/assets/images/unpin-image.svg)`
      }}
      onClick={this.handleTogglePin}
    />
  );

  onDrawCreate = event => {
    this.setState({ geojson: event.layer.toGeoJSON().geometry });
  };

  onDrawDelete = () => {
    this.setState({ geojson: null });
  };

  handleMapTextChange(event) {
    this.setState({ mapCommentText: event.target.value });
  }

  getMapBorder() {
    const { hearingGeojson } = this.props;
    if (hearingGeojson && hearingGeojson.type === 'Polygon') {
      const contents = [];
      const latLangs = hearingGeojson.coordinates[0].map(
        ([lng, lat]) => new LatLng(lat, lng),
      );
      contents.push(
        <Polygon
          key={Math.random()}
          positions={latLangs}
          color="transparent"
        />,
      );
      return contents;
    }
    return null;
  }

  getMapCenter() {
    const { hearingGeojson } = this.props;
    let center;
    if (hearingGeojson && hearingGeojson.type === 'Point') {
      center = new LatLng(
        hearingGeojson.coordinates[1],
        hearingGeojson.coordinates[0],
      );
    } else {
      center = new LatLng(
        localization.mapPosition[0],
        localization.mapPosition[1],
      );
    }
    return center;
  }

  getMapContrastTiles() {
    const { isHighContrast, language } = this.props;
    return getCorrectContrastMapTileUrl(
      urls.rasterMapTiles,
      urls.highContrastRasterMapTiles,
      isHighContrast,
      language,
    );
  }

  getId(prefix) {
    return prefix + '-' + this.id;
  }

  addEmojiToCommentSelectionStart(emoji) {
    const cursorPosition = document.getElementById(this.getId('commentTextField')).selectionStart;
    const commentText = this.state.commentText;
    const textBefore = commentText.slice(0, cursorPosition);
    const textAfter = commentText.slice(cursorPosition);
    this.setState({ commentText: textBefore + emoji + textAfter });
    document.getElementById(this.getId('commentTextField')).setSelectionRange(cursorPosition + 1, cursorPosition + 1);
  }

  setCommentSticker(stickerId) {
    this.setState({ sticker: stickerId });
  }

  render() {
    const { language, section, user, isReply, allowFiles } = this.props;

    return (
      <div className="comment-form">
        <form>
          <div className="comment-form__heading-container">
            <div className="comment-form__heading-container__title">
              <label htmlFor="commentTextField" className="h4">
                <FormattedMessage id="writeComment" />
              </label>
            </div>
            {this.isUserAdmin() && !isReply && (
              <div className="comment-form__heading-container__pin">
                {this.renderPinUnpinIcon()}
              </div>
            )}
          </div>
          {
            this.state.sticker &&
            <div>
              <Sticker stickerId={this.state.sticker} />
              <Button onClick={() => this.setCommentSticker(null)}>
                <FormattedMessage id="delete" />
              </Button>
            </div>
          }
          <div className='input-container'>
            <div className='textarea-container'>
              <FormControl
                autoFocus
                className="comment-textarea"
                componentClass="textarea"
                value={this.state.commentText}
                onChange={this.handleTextChange.bind(this)}
                id={this.getId('commentTextField')}
              />
            </div>
            <DigiraatiEmojiInput
            emojiSelectionHandler={this.addEmojiToCommentSelectionStart.bind(this)}
            stickerSelectionHandler={this.setCommentSticker.bind(this)}
            selectedSticker={this.state.sticker}
          />
          {allowFiles && (<>
            <div className="comment-form__selected-images">
              {this.state.imageTooBig ? (
                <div className="comment-form__image-too-big">
                  <FormattedMessage id="imageSizeError" />
                </div>
              ) : (
                this.state.images.map((image, key) => (
                  <img
                    style={{ marginRight: 10 }}
                    alt=""
                    src={image.image}
                    width={image.width < 100 ? image.width : 100}
                    height={image.height < 100 ? image.width : 100}
                    key={key + Math.random()} //eslint-disable-line
                  />
                ))
              )}
            </div>
            <FormGroup className="comment-form__file">
              <ControlLabel>
                <FormattedMessage id="add_images" />
              </ControlLabel>
              <div className="comment-form__select-file">
                <input
                  type="file"
                  ref="images"
                  id={this.getId('fileInput')}
                  multiple
                  className="custom-file-input"
                  onChange={event => this.handleChange(event)}
                />
                <label className="digi-button btn btn-sm" htmlFor={this.getId('fileInput')}>
                  <FormattedMessage id="choose_images" />
                </label>
              </div>
              <span style={{ fontSize: 13, marginTop: 20 }}>
              <FormattedMessage id="multipleImages" />
            </span>
            </FormGroup>
          </>)}
          {this.isUserAdmin() && this.renderNameFormForUser()}
          <div className="comment-buttons clearfix">
            <button type='button' className='digi-button btn' onClick={this.toggle.bind(this)}>
              <FormattedMessage id="cancel" />
            </button>
            <button type='button' className='digi-button btn'
              disabled={!this.state.commentText || this.state.imageTooBig}
              onClick={this.submitComment.bind(this)}
            >
              <FormattedMessage id="submit" />
            </button>
          </div>
          </div>
          
        </form>
      </div>
    );
  }
}

BaseCommentForm.propTypes = {
  canComment: PropTypes.bool,
  onPostComment: PropTypes.func,
  onOverrideCollapse: PropTypes.func,
  intl: intlShape,
  collapseForm: PropTypes.bool,
  defaultNickname: PropTypes.string,
  nicknamePlaceholder: PropTypes.string,
  section: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
  isReply: PropTypes.bool,
  isHighContrast: PropTypes.bool,
  hearingGeojson: PropTypes.object,
  allowFiles: PropTypes.bool,
  hideNameForm: PropTypes.bool,
  setEditing: PropTypes.func,
  cancelEditing: PropTypes.func,
  editedText: PropTypes.string,
  hasDelayToSaveType: PropTypes.bool,
};

BaseCommentForm.defaultProps = {
  defaultNickname: '',
  onOverrideCollapse: () => {
  },
  isReply: false,
  allowFiles: true,
  hasDelayToSaveType: false,
};

const mapStateToProps = state => ({
  isHighContrast: state.accessibility.isHighContrast,
});
const WrappedBaseCommentForm = connect(
  mapStateToProps,
  null,
)(injectIntl(BaseCommentForm));
export default WrappedBaseCommentForm;
