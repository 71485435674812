import React from "react"
import { COMMENT_STICKERS } from "../data/commentStickers";
import Sticker from "./Hearing/sortable-comment-list/comment-list/comment/Sticker";
import ArrowNavigationGrid from "./ArrowNavigationGrid";
import { Button, ControlLabel } from "react-bootstrap";
import { buttonKeyId } from "../utils/emojiUtils";
import { FormattedMessage } from "react-intl";

const StickerSelector = ({selectionHandler, selectedSticker}) => {

  const clearSticker = () => {
    selectionHandler(null)
  }

  const selectSticker = (stickerId) => {
    selectionHandler(stickerId);
  }
  const buttonKey = 'sticker'

  const isSelected = (stickerId) => (
    stickerId == selectedSticker
      ? 'selected'
      : ''
  )

  return (
    <div className='sticker-select'>
      <ControlLabel>
        <FormattedMessage id="selectSticker" />
      </ControlLabel>
      <ArrowNavigationGrid buttonKey={buttonKey} escapeId='button-stickers' 
        max={COMMENT_STICKERS.length} maxContent="120px">
            <Button id={buttonKeyId(buttonKey,0)} 
              className={[buttonKey,isSelected(null)].join(' ')}
              onClick={clearSticker}
            >
              <FormattedMessage id='stickerNone'/>
            </Button>
          {
            COMMENT_STICKERS.map( (sticker, i) => (
              <Button key={sticker.id}
                id={buttonKeyId(buttonKey,i+1)}
                className={[buttonKey,isSelected(sticker.id)].join(' ')}
                onClick={() => {selectSticker(sticker.id)}} 
                style={{padding:0, width: 'fit-content', display: 'block'}}
              >
                <Sticker stickerId={sticker.id} />
              </Button>
            ) )
          }
      </ArrowNavigationGrid>
    </div>
  )
}

export default StickerSelector;