import {setHeadless} from '../store/actions';
import {checkHeadlessParam} from '../utils/urlQuery';
import { cleanState } from '../store/actions/commentEditorActions';

export const headlessFromUrlMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    store.dispatch(setHeadless(checkHeadlessParam(window.location.search)));
    store.dispatch(cleanState());
  }
  return next(action);
};

export default headlessFromUrlMiddleware;
