import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const StyledButton = styled(LoadingButton)`
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    padding: 8px 20px;
    line-height: 1.5;
    background-color: $digiraati-primary-color;
    color: $black;
    border: 1px solid $digiraati-primary-color;
    border-radius: 20px;
    font-family: "PT Sans";
    font-weight: bold;
    letter-spacing: 0.54px;

    &.Mui-disabled {
        color: ${(p) => (p.islight ? '#983ce2' : 'white')};
        box-shadow: none;
        opacity: 0.65;
    }

    :hover {
        color: black;
        border-color: black;
        box-shadow: none;
        background-color: ${(p) => (p.islight ? 'white' : '#983ce2')};
    }

)`;

const DigiraatiButton = ({
                           style,
                           theme,
                           buttonLabel = 'Button',
                           onClick,
                           className,
                           disabled,
                           isLoading,
                           role = 'button',
                         }) => {
  return (
    <StyledButton
      role={role}
      className={className}
      sx={{
        '& .MuiButtonBase-root': {
          '& :focus': {
            boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
          },
        },
        ...style,
      }}
      islight={theme === 'light'}
      onClick={onClick}
      onKeyDown={(event) => {
        event.persist();
      }}
      aria-label={buttonLabel}
      disabled={disabled}
      loading={isLoading}
    >
      {buttonLabel}
    </StyledButton>
  );
};

DigiraatiButton.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.oneOf(['light', 'dark']),
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

DigiraatiButton.defaultProps = {
  sx: undefined,
  theme: 'light',
  disabled: false,
  isLoading: false,
};

export default injectIntl(DigiraatiButton);