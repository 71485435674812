import api from '../api';
import { requestErrorHandler } from '../store/actions';

const baseUrl = '/v1/voted-comments/';

export const fetchUserVotedComments = async () => {

  const paramsWithLimit = { limit: 10 };
  const response = await api.get({}, baseUrl, paramsWithLimit)
    .then(data => data.json())
    .catch(() => requestErrorHandler());
  return response.results;


};