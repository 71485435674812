import { createSelector } from 'reselect';


export const getCommentEditingStateById =
  createSelector([(state) => state.commentEditor, (state, commentId) => commentId, (state, commentId, sectionId) => sectionId],
    (commentEditor, commentId, sectionId) => {
      const hasEditingIds = !!commentEditor[sectionId] && !!commentEditor[sectionId].editingIds;
      if (hasEditingIds && commentEditor[sectionId].editingIds.includes(commentId)) {
        return true;
      }
      return false;
    });

export const getCommentEditingTextById =
  createSelector([(state) => state.commentEditor, (state, commentId) => commentId, (state, commentId, sectionId) => sectionId],
    (commentEditor, commentId, sectionId) => {
      const hasEditingIds = !!commentEditor[sectionId] && !!commentEditor[sectionId].editingIds;
      if (hasEditingIds && commentEditor[sectionId].editingIds.includes(commentId)) {
        return commentEditor[sectionId].editing[commentId];
      }
      return null;
    });

export const getCommentEditingStateSectionId =
  createSelector([(state) => state.commentEditor, (state, sectionId) => sectionId],
    (commentEditor, sectionId) => {
      return commentEditor[sectionId];
    });


export const getNewSectionCommentEditingTextById =
  createSelector([(state) => state.commentEditor, (state, sectionId) => sectionId],
    (commentEditor, sectionId) => {
      const hasEditingSectionComment = !!commentEditor[sectionId] && !!commentEditor[sectionId].newSectionComment;
      return hasEditingSectionComment ? commentEditor[sectionId].newSectionComment : null;
    });

export const getReplyEditingTextById =
  createSelector([(state) => state.commentEditor, (state, commentId) => commentId, (state, commentId, sectionId) => sectionId],
    (commentEditor, commentId, sectionId) => {
      const hasReplyingIds = !!commentEditor[sectionId] && !!commentEditor[sectionId].replyingIds;
      if (hasReplyingIds && commentEditor[sectionId].replyingIds.includes(commentId)) {
        return commentEditor[sectionId].replying[commentId];
      }
      return null;
    });


export const hasAnyEditing =
  createSelector([
      (state) => state.commentEditor,
      (state, sectionId) => sectionId,
    ],
    (commentEditor, sectionId) => {
      const hasEditingSectionComment = !!commentEditor[sectionId]?.newSectionComment;
      const hasEditingIds = !!commentEditor[sectionId]?.editingIds?.length;
      const hasReplyingIds = !!commentEditor[sectionId]?.replyingIds?.length;
      const hasAnyEditingState = hasEditingSectionComment || hasReplyingIds || hasEditingIds;

      return hasAnyEditingState;
    });
