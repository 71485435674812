/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import Link from './LinkWithLang';

export default function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <Row className="flex-row">
          <Col md={4} sm={5}>
            <div className="footer-branding">
              <FormattedMessage id="footerLogoAlt">
                {altText => (
                  <img
                    alt={altText}
                    src="/assets/images/om_logo.svg"
                    className="footer-logo"
                  />
                )}
              </FormattedMessage>
              <div className="footer-city-link">
                <FormattedMessage id="footerLogoDescription" />
              </div>
            </div>
          </Col>
          <Col md={4} sm={5}>
            <div className="site-footer-block">
              <FormattedMessage id="footerPartnersText" />
            </div>
          </Col>
          <Col md={4} sm={5}>
            <div className="site-footer-block">
              <ul className="footer-links">
                <li>
                  <Link to={{ path: '/hearings/list' }}>
                    <u>
                      <FormattedMessage id="hearingJuryHeaderText" />
                    </u>
                  </Link>
                </li>
                <li>
                  <Link to={{ path: '/info' }}>
                    <u>
                      <FormattedMessage id="infoHeaderText" />
                    </u>
                  </Link>
                </li>
                <li>
                  <Link to={{ path: '/participate' }}>
                    <u>
                      <FormattedMessage id="howToParticipate" />
                    </u>
                  </Link>
                </li>
                <li>
                  <Link to={{ path: '/host-instructions' }}>
                    <u>
                      <FormattedMessage id="instructionsForHearingHosts" />
                    </u>
                  </Link>
                </li>
                <li>
                  <Link to={{ path: '/hearing-creation-instructions' }}>
                    <u>
                      <FormattedMessage id="newHearingCreatingInstractionLabel" />
                    </u>
                  </Link>
                </li>
                <li>
                  <Link to={{ path: '/terms-of-use-and-moderation' }}>
                    <u>
                      <FormattedMessage id="termsOfUseAndModerationLabel" />
                    </u>
                  </Link>
                </li>
                <hr className="footer-hr" />
                <li>
                  <Link to={{ path: '/privacy' }} style={{ color: '#D2D5D6' }}>
                    <FormattedMessage id="footerPrivacy" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ path: '/accessibility' }}
                    style={{ color: '#D2D5D6' }}
                  >
                    <FormattedMessage id="accessibilityLink" />
                  </Link>
                </li>
                <li>
                  <Link to={{ path: '/info' }} style={{ color: '#D2D5D6' }}>
                    <FormattedMessage id="footerContacts" />
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
