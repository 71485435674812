import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button';

import config from '../../config';
import { getUser } from '../../store/selectors/user';

//import 'hds-core';
import 'hds-core/lib/components/text-input/text-input.css';
import 'hds-core/lib/components/button/button.css';
import './styles.css';
import getMessage from '../../utils/getMessage';
import { localizedNotifyError } from '../../utils/notify';
import { getLinkToBackend } from '../../api';
import { Helmet } from 'react-helmet';

class Login extends React.Component {
  form = { username: '', password: '' };

  static getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return "";
  }

  static async logout() {
    try {
      await fetch(`${config.apiBaseUrl}/oidc/logout/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-csrftoken': Login.getCookie('kkdev-csrftoken'),
        },
      });
    } catch (e) {
      console.error(e)
    }
    try {
      const token = localStorage.getItem('token');
      await fetch(`${config.apiBaseUrl}/v1/auth/logout/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + token,
        },
      });
      localStorage.removeItem('token');
      window.open('/', '_self');
    } catch (e) {
      throw new Error('Couldn\'t log out.');
    }
  }

  onSubmit = async e => {
    const { language } = this.props;
    const params = new URLSearchParams(window.location.search);
    e.preventDefault();
    try {
      const data =
        this.form.username.includes('@')
          ? {
            email: this.form.username,
            password: this.form.password,
          }
          : this.form;
      const result = await fetch(`${config.apiBaseUrl}/v1/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await result.json();
      if (result.status === 400) {
        if (response.non_field_errors.includes('E-mail is not verified.')) {
          localizedNotifyError('loginFailUnverified');
        } else {
          localizedNotifyError('loginFail');
        }
      }
      if (response.token) {
        localStorage.setItem('token', response.token);
        const next = params.get('next');
        const addLanguageToUrl = (toString) => {
          return toString.includes('?')
            ? toString + `&lang=${language}`
            : toString + `?lang=${language}`
        }
        if (next && !next.includes('.') && !next.includes(':')) {
          window.open(addLanguageToUrl(next), '_self');
        } else {
          window.open(addLanguageToUrl('/'), '_self');
        }
      }
    } catch (e) {
      localizedNotifyError('loginFail');
      throw new Error('Couldn\'t log in.');
    }
  };

  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => {
      this.form[name] = value;
    });
  };

  getRegistrationButton() {
    const { language } = this.props;
    if (this.props.user) {
      return null;
    }
    return [
      <FormattedMessage
        key="signup"
        id="signup"
      >
        {signup => (
          <Link
            to={`/auth/registration?lang=${language}`}
          >
            <button type='button'
              key="signup"
              aria-label={signup}
              className="digi-button"
            >
              <span>{signup}</span>
            </button>
          </Link>
        )}
      </FormattedMessage>,
    ];
  }

  getMpassIdLoginButton() {
    if (!config.enableMPASSid || this.props.user) {
      return null;
    }
    return [
      <FormattedMessage
        key="signup"
        id="signup"
      >
        {signup => (
          <Link
            to={getLinkToBackend('/oidc/authenticate')}
          >
            <Button
              key="signup"
              aria-label={signup}
              className="digi-button"
            >
              <img
                src={'https://wiki.eduuni.fi/download/attachments/262275211/Symboli.png?version=1&modificationDate=1646230697337&api=v2'}
                alt={'MPASSid logo'}
                style={{ height: "20px", marginRight: "5px"}}
              />
              <span>Kirjaudu MPASSid:llä</span>
            </Button>
          </Link>
        )}
      </FormattedMessage>,
    ];
  }

  render() {
    const registrationButton = this.getRegistrationButton();
    const mpassIdLoginButton = this.getMpassIdLoginButton();

    return (
      <form
        className="hds-form hds-form--login"
        onSubmit={this.onSubmit}
      >
        <Helmet title={getMessage('login')} />
        <h3><FormattedMessage id='login'/></h3>
        <div className="hds-card">
          <fieldset className="hds-fieldset">
            <div className="hds-text-input">
              <label htmlFor="username">
                {getMessage('username/email')} *
              </label>
              <div className="hds-text-input__input-wrapper">
                <input
                  required
                  id="username"
                  name="username"
                  className="form-control"
                  type="text"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="hds-text-input">
              <label htmlFor='password'>
                {getMessage('password')} *
              </label>
              <div className="hds-text-input__input-wrapper">
                <input
                  required
                  id="password"
                  name="password"
                  className="form-control"
                  type="password"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="space-between">
              <button
                type="submit"
                className="digi-button"
              >
                <span className="hds-button__label">{getMessage('loginToSystem')}</span>
              </button>
              <div className="align-right">
                <Link to={`/password-reset?lang=${this.props.language}`}>
                  {getMessage('didForgotPassword')}
                </Link> 
              </div>
            </div>
          </fieldset>
          {mpassIdLoginButton}
          {registrationButton}
        </div>
      </form>
    );
  }


}

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(state => ({
  user: getUser(state), // User dropdown requires this state
  language: state.language, // Language switch requires this state
  router: state.router, // Navigation activity requires this state
}), mapDispatchToProps)(Login));