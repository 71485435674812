import { FormHelperText } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import getMessage from '../../../utils/getMessage';
import { boolean } from 'yup';
import Link from '../../../components/LinkWithLang';

export const DigiraatiPrivacyCheckboxValidationSchema = {
  is_approved_privacy: boolean().isTrue('registrationFailedPrivacyNotApproved'),
};

/** For use as a Formik Field component */
const DigiraatiPrivacyCheckbox = ({
                                    field,
                                    form,
                                    ...props
                                  }) => {
  const { touched, errors } = form;

  return (
    <div className="hds-privacy-check-box">
      <label className="checkbox-label">
        <div className="custom-checkbox-container">
          <input
            type="checkbox"
            name="is_approved_privacy"
            checked={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={form.isSubmitting}
          />
          <div className="custom-checkbox" />
        </div>
        <div>
          <ul>
            <li>
              <Link to={{ path: '/privacy' }} target="_blank">
                <FormattedMessage id="privacyLinkText" />
              </Link>
              <FormattedMessage id="andText" />
            </li>
            <li>
              <Link to={{ path: '/terms-of-use-and-moderation' }} target="_blank">
                <FormattedMessage id="participateLinkText" />
              </Link>
            </li>
          </ul>
        </div>
      </label>
      <FormHelperText
        error={Boolean(touched.is_approved_privacy && errors.is_approved_privacy)}
      >
        {touched.is_approved_privacy && errors.is_approved_privacy
          ? getMessage(errors.is_approved_privacy)
          : ' '}
      </FormHelperText>
    </div>
  );
};

export default DigiraatiPrivacyCheckbox;