import PropTypes from 'prop-types';
import LabelList from '../LabelList';
import React from 'react';
import styled from 'styled-components';


export const StyledDiv = styled.div`

    & .hearing-join-request-button-wrap {
        display: flex;
        justify-content: end;
        font: normal normal bold 12px PT Sans;
        padding-bottom: 10px;
        padding-right: 10px;
`;

const HearingCardLabels = ({ hearing, language }) => {

  return (
    <StyledDiv hasUser={hearing.user}>
      <div className="hearing-card-topbar"
           style={{ paddingBottom: !hearing.can_access_detail && '0' }}>
        <div>
          <LabelList
            className="hearing-list-item-labellist"
            labels={hearing.labels}
            language={language}
          />
        </div>
      </div>
    </StyledDiv>
  );
};

HearingCardLabels.propTypes = {
  hearing: PropTypes.object,
  language: PropTypes.string,
};

export default HearingCardLabels;