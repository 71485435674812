import PropTypes from 'prop-types';
import React from 'react';

const CommentImageComponent = ({ images }) => {
  return (
    <div className="hearing-comment__images">
      {images
        ? images.map(image => (
          // eslint-disable-next-line react/jsx-indent
          <a
            className="hearing-comment-images-image"
            key={image.url}
            rel="noopener noreferrer"
            target="_blank"
            href={image.url}
          >
            <img
              alt=""
              src={image.url}
              width={image.width < 100 ? image.width : 100}
              height={image.height < 100 ? image.height : 100}
            />
          </a>
        ))
        : null}
    </div>
  );
};

CommentImageComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default CommentImageComponent;