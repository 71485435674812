import { createAction } from 'redux-actions';

export const MainActions = {
  SET_IS_EDITING_COMMENT: 'setIsEditingComment',
  REMOVE_IS_EDITING_COMMENT: 'removeIsEditingComment',
  SET_NEW_SECTION_COMMENT: 'setEditingNewSectionComment',
  REMOVE_NEW_SECTION_COMMENT: 'removeEditingNewSectionComment',
  SET_IS_EDITING_REPLAY: 'setIsEditingCommentReplay',
  REMOVE_EDITING_REPLAY: 'removeEditingCommentReplay',
  UPDATE_EDITING_STATE: 'updateEditingState',
  CLEAN_STATE: 'cleanState',
};

export const setIsEditingComment = (commentId, sectionId, value) => {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);

    const setAction = createAction(MainActions.SET_IS_EDITING_COMMENT)({
      sectionId,
      commentId,
      value,
    });
    dispatch(setAction);
    const updateAction = createAction(MainActions.UPDATE_EDITING_STATE)({
      sectionId,
    });
    dispatch(updateAction);

  };
};

export const removeIsEditingComment = (commentId, sectionId) => {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);

    const removeAction = createAction(MainActions.REMOVE_IS_EDITING_COMMENT)({
      sectionId,
      commentId,
    });
    dispatch(removeAction);

    const updateAction = createAction(MainActions.UPDATE_EDITING_STATE)({
      sectionId,
    });
    dispatch(updateAction);
  };
};

export const setEditingNewSectionComment = (sectionId, value) => {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);

    const setAction = createAction(MainActions.SET_NEW_SECTION_COMMENT)({
      sectionId,
      value,
    });
    dispatch(setAction);

    const updateAction = createAction(MainActions.UPDATE_EDITING_STATE)({
      sectionId,
    });
    dispatch(updateAction);

  };
};

export const removeEditingNewSectionComment = (sectionId) => {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);

    const removeAction = createAction(MainActions.REMOVE_NEW_SECTION_COMMENT)({
      sectionId,
    });
    dispatch(removeAction);

    const updateAction = createAction(MainActions.UPDATE_EDITING_STATE)({
      sectionId,
    });
    dispatch(updateAction);

  };
};

export const setIsEditingReply = (commentId, sectionId, value) => {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);

    const setAction = createAction(MainActions.SET_IS_EDITING_REPLAY)({
      sectionId,
      commentId,
      value,
    });
    dispatch(setAction);

    const updateAction = createAction(MainActions.UPDATE_EDITING_STATE)({
      sectionId,
    });
    dispatch(updateAction);

  };
};

export const removeEditingReply = (commentId, sectionId) => {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);

    const removeAction = createAction(MainActions.REMOVE_EDITING_REPLAY)({
      sectionId,
      commentId,
    });
    dispatch(removeAction);

    const updateAction = createAction(MainActions.UPDATE_EDITING_STATE)({
      sectionId,
    });
    dispatch(updateAction);

  };
};

export const cleanState = () => {
  return (dispatch) => {
    const cleanAction = createAction(MainActions.CLEAN_STATE)();
    dispatch(cleanAction);

  };
};