import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button} from "react-bootstrap";
import config from '../../config';
import { withRouter} from "react-router-dom";
import {stringify} from 'qs';


import getMessage from "../../utils/getMessage";

const LanguageSwitcher = ({
  history,
  location,
}) => {
  const changeLanguage = (history, location, nextLang) => {
    const languageParam = stringify({lang: nextLang});
    let searchParams;
    if (location.search.includes('lang=')) {
      searchParams = location.search.replace(/lang=\w{2}/, languageParam);
    } else if (location.search) {
      searchParams = location.search + `&${languageParam}`;
    }
    history.push({
      pathname: location.pathname,
      search: searchParams || languageParam
    });
  }

  const newLanguage = () => {
    const languageIndex = config.languages.findIndex((lang) => (lang == config.activeLanguage));
    const nextLanguage = config.languages[ (languageIndex+1) % config.languages.length ];
    return nextLanguage;
  }
  return (
    <div
      className="btn-group-no-padding"
      // eslint-disable-next-line no-return-assign
      ref={node => node = node}
    >
      <Button
        aria-label={getMessage(`lang-${newLanguage()}`)}
        lang={newLanguage()}
        className="language-button"
        onClick={() => {
          changeLanguage(history, location, newLanguage());
        }}
      > 
      <span className="language-title-xs" lang={newLanguage()}>{newLanguage()}</span>
      <span className="language-title" lang={newLanguage()}>{getMessage(`lang-${newLanguage()}`)}</span>
      </Button>
    </div>
  );
}

LanguageSwitcher.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};
export { LanguageSwitcher as UnconnectedLanguageSwitcher};
export default withRouter(connect()(LanguageSwitcher));
