import React, { useRef, useState, useEffect } from "react";

/**
 * A container that reacts to being in the browsers viewport
 * (=on screen).
 * @param {*} param0 
 * @returns 
 */
const IntersectionObserverContainer = ({ 
  children,
  onEnteringIntoView = () => console.log('entered into view'),
  delay = 0,
}) => {

  const containerRef = useRef();
  const [isInView, setIsInView] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting)
    }, {threshold:[0.5]});
    observer.observe(container);

    return () => {
      observer.unobserve(container);
    }
  }, [])

  useEffect(() => {
    let timeout;
    if (isInView && !done) {
      setDone(true);
      if (!delay) {
        onEnteringIntoView()
      } else {
        timeout = setTimeout(() => {
          onEnteringIntoView()
        }, delay)
      }
    }
    return () => clearTimeout(timeout);
  }, [isInView])

  return (
    <div ref={containerRef}>
      {children}
    </div>
  )

}

export default IntersectionObserverContainer;