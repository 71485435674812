// https://unicode.org/Public/emoji/15.1/emoji-sequences.txt

export const EMOJI_FLAGS = [
  '1F1E6 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ascension Island                                         # E2.0   [1] (🇦🇨)
  '1F1E6 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Andorra                                                  # E2.0   [1] (🇦🇩)
  '1F1E6 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: United Arab Emirates                                     # E2.0   [1] (🇦🇪)
  '1F1E6 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Afghanistan                                              # E2.0   [1] (🇦🇫)
  '1F1E6 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Antigua & Barbuda                                        # E2.0   [1] (🇦🇬)
  '1F1E6 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Anguilla                                                 # E2.0   [1] (🇦🇮)
  '1F1E6 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Albania                                                  # E2.0   [1] (🇦🇱)
  '1F1E6 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Armenia                                                  # E2.0   [1] (🇦🇲)
  '1F1E6 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Angola                                                   # E2.0   [1] (🇦🇴)
  '1F1E6 1F1F6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Antarctica                                               # E2.0   [1] (🇦🇶)
  '1F1E6 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Argentina                                                # E2.0   [1] (🇦🇷)
  '1F1E6 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: American Samoa                                           # E2.0   [1] (🇦🇸)
  '1F1E6 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Austria                                                  # E2.0   [1] (🇦🇹)
  '1F1E6 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Australia                                                # E2.0   [1] (🇦🇺)
  '1F1E6 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Aruba                                                    # E2.0   [1] (🇦🇼)
  '1F1E6 1F1FD', //  ; RGI_Emoji_Flag_Sequence      ; flag: Åland Islands                                            # E2.0   [1] (🇦🇽)
  '1F1E6 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Azerbaijan                                               # E2.0   [1] (🇦🇿)
  '1F1E7 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bosnia & Herzegovina                                     # E2.0   [1] (🇧🇦)
  '1F1E7 1F1E7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Barbados                                                 # E2.0   [1] (🇧🇧)
  '1F1E7 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bangladesh                                               # E2.0   [1] (🇧🇩)
  '1F1E7 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Belgium                                                  # E2.0   [1] (🇧🇪)
  '1F1E7 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Burkina Faso                                             # E2.0   [1] (🇧🇫)
  '1F1E7 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bulgaria                                                 # E2.0   [1] (🇧🇬)
  '1F1E7 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bahrain                                                  # E2.0   [1] (🇧🇭)
  '1F1E7 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Burundi                                                  # E2.0   [1] (🇧🇮)
  '1F1E7 1F1EF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Benin                                                    # E2.0   [1] (🇧🇯)
  '1F1E7 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Barthélemy                                           # E2.0   [1] (🇧🇱)
  '1F1E7 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bermuda                                                  # E2.0   [1] (🇧🇲)
  '1F1E7 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Brunei                                                   # E2.0   [1] (🇧🇳)
  '1F1E7 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bolivia                                                  # E2.0   [1] (🇧🇴)
  '1F1E7 1F1F6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Caribbean Netherlands                                    # E2.0   [1] (🇧🇶)
  '1F1E7 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Brazil                                                   # E2.0   [1] (🇧🇷)
  '1F1E7 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bahamas                                                  # E2.0   [1] (🇧🇸)
  '1F1E7 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bhutan                                                   # E2.0   [1] (🇧🇹)
  '1F1E7 1F1FB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Bouvet Island                                            # E2.0   [1] (🇧🇻)
  '1F1E7 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Botswana                                                 # E2.0   [1] (🇧🇼)
  '1F1E7 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Belarus                                                  # E2.0   [1] (🇧🇾)
  '1F1E7 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Belize                                                   # E2.0   [1] (🇧🇿)
  '1F1E8 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Canada                                                   # E2.0   [1] (🇨🇦)
  '1F1E8 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cocos (Keeling) Islands                                  # E2.0   [1] (🇨🇨)
  '1F1E8 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Congo - Kinshasa                                         # E2.0   [1] (🇨🇩)
  '1F1E8 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Central African Republic                                 # E2.0   [1] (🇨🇫)
  '1F1E8 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Congo - Brazzaville                                      # E2.0   [1] (🇨🇬)
  '1F1E8 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Switzerland                                              # E2.0   [1] (🇨🇭)
  '1F1E8 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Côte d’Ivoire                                            # E2.0   [1] (🇨🇮)
  '1F1E8 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cook Islands                                             # E2.0   [1] (🇨🇰)
  '1F1E8 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Chile                                                    # E2.0   [1] (🇨🇱)
  '1F1E8 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cameroon                                                 # E2.0   [1] (🇨🇲)
  '1F1E8 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: China                                                    # E0.6   [1] (🇨🇳)
  '1F1E8 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Colombia                                                 # E2.0   [1] (🇨🇴)
  '1F1E8 1F1F5', //  ; RGI_Emoji_Flag_Sequence      ; flag: Clipperton Island                                        # E2.0   [1] (🇨🇵)
  '1F1E8 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Costa Rica                                               # E2.0   [1] (🇨🇷)
  '1F1E8 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cuba                                                     # E2.0   [1] (🇨🇺)
  '1F1E8 1F1FB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cape Verde                                               # E2.0   [1] (🇨🇻)
  '1F1E8 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Curaçao                                                  # E2.0   [1] (🇨🇼)
  '1F1E8 1F1FD', //  ; RGI_Emoji_Flag_Sequence      ; flag: Christmas Island                                         # E2.0   [1] (🇨🇽)
  '1F1E8 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cyprus                                                   # E2.0   [1] (🇨🇾)
  '1F1E8 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Czechia                                                  # E2.0   [1] (🇨🇿)
  '1F1E9 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Germany                                                  # E0.6   [1] (🇩🇪)
  '1F1E9 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Diego Garcia                                             # E2.0   [1] (🇩🇬)
  '1F1E9 1F1EF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Djibouti                                                 # E2.0   [1] (🇩🇯)
  '1F1E9 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Denmark                                                  # E2.0   [1] (🇩🇰)
  '1F1E9 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Dominica                                                 # E2.0   [1] (🇩🇲)
  '1F1E9 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Dominican Republic                                       # E2.0   [1] (🇩🇴)
  '1F1E9 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Algeria                                                  # E2.0   [1] (🇩🇿)
  '1F1EA 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ceuta & Melilla                                          # E2.0   [1] (🇪🇦)
  '1F1EA 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ecuador                                                  # E2.0   [1] (🇪🇨)
  '1F1EA 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Estonia                                                  # E2.0   [1] (🇪🇪)
  '1F1EA 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Egypt                                                    # E2.0   [1] (🇪🇬)
  '1F1EA 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Western Sahara                                           # E2.0   [1] (🇪🇭)
  '1F1EA 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Eritrea                                                  # E2.0   [1] (🇪🇷)
  '1F1EA 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Spain                                                    # E0.6   [1] (🇪🇸)
  '1F1EA 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ethiopia                                                 # E2.0   [1] (🇪🇹)
  '1F1EA 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: European Union                                           # E2.0   [1] (🇪🇺)
  '1F1EB 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Finland                                                  # E2.0   [1] (🇫🇮)
  '1F1EB 1F1EF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Fiji                                                     # E2.0   [1] (🇫🇯)
  '1F1EB 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Falkland Islands                                         # E2.0   [1] (🇫🇰)
  '1F1EB 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Micronesia                                               # E2.0   [1] (🇫🇲)
  '1F1EB 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Faroe Islands                                            # E2.0   [1] (🇫🇴)
  '1F1EB 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: France                                                   # E0.6   [1] (🇫🇷)
  '1F1EC 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Gabon                                                    # E2.0   [1] (🇬🇦)
  '1F1EC 1F1E7', //  ; RGI_Emoji_Flag_Sequence      ; flag: United Kingdom                                           # E0.6   [1] (🇬🇧)
  '1F1EC 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Grenada                                                  # E2.0   [1] (🇬🇩)
  '1F1EC 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Georgia                                                  # E2.0   [1] (🇬🇪)
  '1F1EC 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: French Guiana                                            # E2.0   [1] (🇬🇫)
  '1F1EC 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guernsey                                                 # E2.0   [1] (🇬🇬)
  '1F1EC 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ghana                                                    # E2.0   [1] (🇬🇭)
  '1F1EC 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Gibraltar                                                # E2.0   [1] (🇬🇮)
  '1F1EC 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Greenland                                                # E2.0   [1] (🇬🇱)
  '1F1EC 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Gambia                                                   # E2.0   [1] (🇬🇲)
  '1F1EC 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guinea                                                   # E2.0   [1] (🇬🇳)
  '1F1EC 1F1F5', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guadeloupe                                               # E2.0   [1] (🇬🇵)
  '1F1EC 1F1F6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Equatorial Guinea                                        # E2.0   [1] (🇬🇶)
  '1F1EC 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Greece                                                   # E2.0   [1] (🇬🇷)
  '1F1EC 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: South Georgia & South Sandwich Islands                   # E2.0   [1] (🇬🇸)
  '1F1EC 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guatemala                                                # E2.0   [1] (🇬🇹)
  '1F1EC 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guam                                                     # E2.0   [1] (🇬🇺)
  '1F1EC 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guinea-Bissau                                            # E2.0   [1] (🇬🇼)
  '1F1EC 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Guyana                                                   # E2.0   [1] (🇬🇾)
  '1F1ED 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Hong Kong SAR China                                      # E2.0   [1] (🇭🇰)
  '1F1ED 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Heard & McDonald Islands                                 # E2.0   [1] (🇭🇲)
  '1F1ED 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Honduras                                                 # E2.0   [1] (🇭🇳)
  '1F1ED 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Croatia                                                  # E2.0   [1] (🇭🇷)
  '1F1ED 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Haiti                                                    # E2.0   [1] (🇭🇹)
  '1F1ED 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Hungary                                                  # E2.0   [1] (🇭🇺)
  '1F1EE 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Canary Islands                                           # E2.0   [1] (🇮🇨)
  '1F1EE 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Indonesia                                                # E2.0   [1] (🇮🇩)
  '1F1EE 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ireland                                                  # E2.0   [1] (🇮🇪)
  '1F1EE 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Israel                                                   # E2.0   [1] (🇮🇱)
  '1F1EE 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Isle of Man                                              # E2.0   [1] (🇮🇲)
  '1F1EE 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: India                                                    # E2.0   [1] (🇮🇳)
  '1F1EE 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: British Indian Ocean Territory                           # E2.0   [1] (🇮🇴)
  '1F1EE 1F1F6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Iraq                                                     # E2.0   [1] (🇮🇶)
  '1F1EE 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Iran                                                     # E2.0   [1] (🇮🇷)
  '1F1EE 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Iceland                                                  # E2.0   [1] (🇮🇸)
  '1F1EE 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Italy                                                    # E0.6   [1] (🇮🇹)
  '1F1EF 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Jersey                                                   # E2.0   [1] (🇯🇪)
  '1F1EF 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Jamaica                                                  # E2.0   [1] (🇯🇲)
  '1F1EF 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Jordan                                                   # E2.0   [1] (🇯🇴)
  '1F1EF 1F1F5', //  ; RGI_Emoji_Flag_Sequence      ; flag: Japan                                                    # E0.6   [1] (🇯🇵)
  '1F1F0 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Kenya                                                    # E2.0   [1] (🇰🇪)
  '1F1F0 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Kyrgyzstan                                               # E2.0   [1] (🇰🇬)
  '1F1F0 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cambodia                                                 # E2.0   [1] (🇰🇭)
  '1F1F0 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Kiribati                                                 # E2.0   [1] (🇰🇮)
  '1F1F0 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Comoros                                                  # E2.0   [1] (🇰🇲)
  '1F1F0 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Kitts & Nevis                                        # E2.0   [1] (🇰🇳)
  '1F1F0 1F1F5', //  ; RGI_Emoji_Flag_Sequence      ; flag: North Korea                                              # E2.0   [1] (🇰🇵)
  '1F1F0 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: South Korea                                              # E0.6   [1] (🇰🇷)
  '1F1F0 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Kuwait                                                   # E2.0   [1] (🇰🇼)
  '1F1F0 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Cayman Islands                                           # E2.0   [1] (🇰🇾)
  '1F1F0 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Kazakhstan                                               # E2.0   [1] (🇰🇿)
  '1F1F1 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Laos                                                     # E2.0   [1] (🇱🇦)
  '1F1F1 1F1E7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Lebanon                                                  # E2.0   [1] (🇱🇧)
  '1F1F1 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Lucia                                                # E2.0   [1] (🇱🇨)
  '1F1F1 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Liechtenstein                                            # E2.0   [1] (🇱🇮)
  '1F1F1 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Sri Lanka                                                # E2.0   [1] (🇱🇰)
  '1F1F1 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Liberia                                                  # E2.0   [1] (🇱🇷)
  '1F1F1 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Lesotho                                                  # E2.0   [1] (🇱🇸)
  '1F1F1 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Lithuania                                                # E2.0   [1] (🇱🇹)
  '1F1F1 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Luxembourg                                               # E2.0   [1] (🇱🇺)
  '1F1F1 1F1FB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Latvia                                                   # E2.0   [1] (🇱🇻)
  '1F1F1 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Libya                                                    # E2.0   [1] (🇱🇾)
  '1F1F2 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Morocco                                                  # E2.0   [1] (🇲🇦)
  '1F1F2 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Monaco                                                   # E2.0   [1] (🇲🇨)
  '1F1F2 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Moldova                                                  # E2.0   [1] (🇲🇩)
  '1F1F2 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Montenegro                                               # E2.0   [1] (🇲🇪)
  '1F1F2 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Martin                                               # E2.0   [1] (🇲🇫)
  '1F1F2 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Madagascar                                               # E2.0   [1] (🇲🇬)
  '1F1F2 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Marshall Islands                                         # E2.0   [1] (🇲🇭)
  '1F1F2 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: North Macedonia                                          # E2.0   [1] (🇲🇰)
  '1F1F2 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mali                                                     # E2.0   [1] (🇲🇱)
  '1F1F2 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Myanmar (Burma)                                          # E2.0   [1] (🇲🇲)
  '1F1F2 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mongolia                                                 # E2.0   [1] (🇲🇳)
  '1F1F2 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Macao SAR China                                          # E2.0   [1] (🇲🇴)
  '1F1F2 1F1F5', //  ; RGI_Emoji_Flag_Sequence      ; flag: Northern Mariana Islands                                 # E2.0   [1] (🇲🇵)
  '1F1F2 1F1F6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Martinique                                               # E2.0   [1] (🇲🇶)
  '1F1F2 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mauritania                                               # E2.0   [1] (🇲🇷)
  '1F1F2 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Montserrat                                               # E2.0   [1] (🇲🇸)
  '1F1F2 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Malta                                                    # E2.0   [1] (🇲🇹)
  '1F1F2 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mauritius                                                # E2.0   [1] (🇲🇺)
  '1F1F2 1F1FB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Maldives                                                 # E2.0   [1] (🇲🇻)
  '1F1F2 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Malawi                                                   # E2.0   [1] (🇲🇼)
  '1F1F2 1F1FD', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mexico                                                   # E2.0   [1] (🇲🇽)
  '1F1F2 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Malaysia                                                 # E2.0   [1] (🇲🇾)
  '1F1F2 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mozambique                                               # E2.0   [1] (🇲🇿)
  '1F1F3 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Namibia                                                  # E2.0   [1] (🇳🇦)
  '1F1F3 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: New Caledonia                                            # E2.0   [1] (🇳🇨)
  '1F1F3 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Niger                                                    # E2.0   [1] (🇳🇪)
  '1F1F3 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Norfolk Island                                           # E2.0   [1] (🇳🇫)
  '1F1F3 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Nigeria                                                  # E2.0   [1] (🇳🇬)
  '1F1F3 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Nicaragua                                                # E2.0   [1] (🇳🇮)
  '1F1F3 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Netherlands                                              # E2.0   [1] (🇳🇱)
  '1F1F3 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Norway                                                   # E2.0   [1] (🇳🇴)
  '1F1F3 1F1F5', //  ; RGI_Emoji_Flag_Sequence      ; flag: Nepal                                                    # E2.0   [1] (🇳🇵)
  '1F1F3 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Nauru                                                    # E2.0   [1] (🇳🇷)
  '1F1F3 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Niue                                                     # E2.0   [1] (🇳🇺)
  '1F1F3 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: New Zealand                                              # E2.0   [1] (🇳🇿)
  '1F1F4 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Oman                                                     # E2.0   [1] (🇴🇲)
  '1F1F5 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Panama                                                   # E2.0   [1] (🇵🇦)
  '1F1F5 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Peru                                                     # E2.0   [1] (🇵🇪)
  '1F1F5 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: French Polynesia                                         # E2.0   [1] (🇵🇫)
  '1F1F5 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Papua New Guinea                                         # E2.0   [1] (🇵🇬)
  '1F1F5 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Philippines                                              # E2.0   [1] (🇵🇭)
  '1F1F5 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Pakistan                                                 # E2.0   [1] (🇵🇰)
  '1F1F5 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Poland                                                   # E2.0   [1] (🇵🇱)
  '1F1F5 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Pierre & Miquelon                                    # E2.0   [1] (🇵🇲)
  '1F1F5 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Pitcairn Islands                                         # E2.0   [1] (🇵🇳)
  '1F1F5 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Puerto Rico                                              # E2.0   [1] (🇵🇷)
  '1F1F5 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Palestinian Territories                                  # E2.0   [1] (🇵🇸)
  '1F1F5 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Portugal                                                 # E2.0   [1] (🇵🇹)
  '1F1F5 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Palau                                                    # E2.0   [1] (🇵🇼)
  '1F1F5 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Paraguay                                                 # E2.0   [1] (🇵🇾)
  '1F1F6 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Qatar                                                    # E2.0   [1] (🇶🇦)
  '1F1F7 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Réunion                                                  # E2.0   [1] (🇷🇪)
  '1F1F7 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Romania                                                  # E2.0   [1] (🇷🇴)
  '1F1F7 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Serbia                                                   # E2.0   [1] (🇷🇸)
  '1F1F7 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Russia                                                   # E0.6   [1] (🇷🇺)
  '1F1F7 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Rwanda                                                   # E2.0   [1] (🇷🇼)
  '1F1F8 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Saudi Arabia                                             # E2.0   [1] (🇸🇦)
  '1F1F8 1F1E7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Solomon Islands                                          # E2.0   [1] (🇸🇧)
  '1F1F8 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Seychelles                                               # E2.0   [1] (🇸🇨)
  '1F1F8 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Sudan                                                    # E2.0   [1] (🇸🇩)
  '1F1F8 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Sweden                                                   # E2.0   [1] (🇸🇪)
  '1F1F8 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Singapore                                                # E2.0   [1] (🇸🇬)
  '1F1F8 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Helena                                               # E2.0   [1] (🇸🇭)
  '1F1F8 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Slovenia                                                 # E2.0   [1] (🇸🇮)
  '1F1F8 1F1EF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Svalbard & Jan Mayen                                     # E2.0   [1] (🇸🇯)
  '1F1F8 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Slovakia                                                 # E2.0   [1] (🇸🇰)
  '1F1F8 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Sierra Leone                                             # E2.0   [1] (🇸🇱)
  '1F1F8 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: San Marino                                               # E2.0   [1] (🇸🇲)
  '1F1F8 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Senegal                                                  # E2.0   [1] (🇸🇳)
  '1F1F8 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Somalia                                                  # E2.0   [1] (🇸🇴)
  '1F1F8 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Suriname                                                 # E2.0   [1] (🇸🇷)
  '1F1F8 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: South Sudan                                              # E2.0   [1] (🇸🇸)
  '1F1F8 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: São Tomé & Príncipe                                      # E2.0   [1] (🇸🇹)
  '1F1F8 1F1FB', //  ; RGI_Emoji_Flag_Sequence      ; flag: El Salvador                                              # E2.0   [1] (🇸🇻)
  '1F1F8 1F1FD', //  ; RGI_Emoji_Flag_Sequence      ; flag: Sint Maarten                                             # E2.0   [1] (🇸🇽)
  '1F1F8 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Syria                                                    # E2.0   [1] (🇸🇾)
  '1F1F8 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Eswatini                                                 # E2.0   [1] (🇸🇿)
  '1F1F9 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tristan da Cunha                                         # E2.0   [1] (🇹🇦)
  '1F1F9 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Turks & Caicos Islands                                   # E2.0   [1] (🇹🇨)
  '1F1F9 1F1E9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Chad                                                     # E2.0   [1] (🇹🇩)
  '1F1F9 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: French Southern Territories                              # E2.0   [1] (🇹🇫)
  '1F1F9 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Togo                                                     # E2.0   [1] (🇹🇬)
  '1F1F9 1F1ED', //  ; RGI_Emoji_Flag_Sequence      ; flag: Thailand                                                 # E2.0   [1] (🇹🇭)
  '1F1F9 1F1EF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tajikistan                                               # E2.0   [1] (🇹🇯)
  '1F1F9 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tokelau                                                  # E2.0   [1] (🇹🇰)
  '1F1F9 1F1F1', //  ; RGI_Emoji_Flag_Sequence      ; flag: Timor-Leste                                              # E2.0   [1] (🇹🇱)
  '1F1F9 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Turkmenistan                                             # E2.0   [1] (🇹🇲)
  '1F1F9 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tunisia                                                  # E2.0   [1] (🇹🇳)
  '1F1F9 1F1F4', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tonga                                                    # E2.0   [1] (🇹🇴)
  '1F1F9 1F1F7', //  ; RGI_Emoji_Flag_Sequence      ; flag: Türkiye                                                  # E2.0   [1] (🇹🇷)
  '1F1F9 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Trinidad & Tobago                                        # E2.0   [1] (🇹🇹)
  '1F1F9 1F1FB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tuvalu                                                   # E2.0   [1] (🇹🇻)
  '1F1F9 1F1FC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Taiwan                                                   # E2.0   [1] (🇹🇼)
  '1F1F9 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Tanzania                                                 # E2.0   [1] (🇹🇿)
  '1F1FA 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Ukraine                                                  # E2.0   [1] (🇺🇦)
  '1F1FA 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: Uganda                                                   # E2.0   [1] (🇺🇬)
  '1F1FA 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: U.S. Outlying Islands                                    # E2.0   [1] (🇺🇲)
  '1F1FA 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: United Nations                                           # E4.0   [1] (🇺🇳)
  '1F1FA 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: United States                                            # E0.6   [1] (🇺🇸)
  '1F1FA 1F1FE', //  ; RGI_Emoji_Flag_Sequence      ; flag: Uruguay                                                  # E2.0   [1] (🇺🇾)
  '1F1FA 1F1FF', //  ; RGI_Emoji_Flag_Sequence      ; flag: Uzbekistan                                               # E2.0   [1] (🇺🇿)
  '1F1FB 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: Vatican City                                             # E2.0   [1] (🇻🇦)
  '1F1FB 1F1E8', //  ; RGI_Emoji_Flag_Sequence      ; flag: St. Vincent & Grenadines                                 # E2.0   [1] (🇻🇨)
  '1F1FB 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Venezuela                                                # E2.0   [1] (🇻🇪)
  '1F1FB 1F1EC', //  ; RGI_Emoji_Flag_Sequence      ; flag: British Virgin Islands                                   # E2.0   [1] (🇻🇬)
  '1F1FB 1F1EE', //  ; RGI_Emoji_Flag_Sequence      ; flag: U.S. Virgin Islands                                      # E2.0   [1] (🇻🇮)
  '1F1FB 1F1F3', //  ; RGI_Emoji_Flag_Sequence      ; flag: Vietnam                                                  # E2.0   [1] (🇻🇳)
  '1F1FB 1F1FA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Vanuatu                                                  # E2.0   [1] (🇻🇺)
  '1F1FC 1F1EB', //  ; RGI_Emoji_Flag_Sequence      ; flag: Wallis & Futuna                                          # E2.0   [1] (🇼🇫)
  '1F1FC 1F1F8', //  ; RGI_Emoji_Flag_Sequence      ; flag: Samoa                                                    # E2.0   [1] (🇼🇸)
  '1F1FD 1F1F0', //  ; RGI_Emoji_Flag_Sequence      ; flag: Kosovo                                                   # E2.0   [1] (🇽🇰)
  '1F1FE 1F1EA', //  ; RGI_Emoji_Flag_Sequence      ; flag: Yemen                                                    # E2.0   [1] (🇾🇪)
  '1F1FE 1F1F9', //  ; RGI_Emoji_Flag_Sequence      ; flag: Mayotte                                                  # E2.0   [1] (🇾🇹)
  '1F1FF 1F1E6', //  ; RGI_Emoji_Flag_Sequence      ; flag: South Africa                                             # E2.0   [1] (🇿🇦)
  '1F1FF 1F1F2', //  ; RGI_Emoji_Flag_Sequence      ; flag: Zambia                                                   # E2.0   [1] (🇿🇲)
  '1F1FF 1F1FC', //   ; RGI_Emoji_Flag_Sequence      ; flag: Zimbabwe                                                 # E2.0   [1] (🇿🇼)
]

export const EMOJI_MODIFIER_SEQUENCES = [
  "261D", //1F3FB    ; RGI_Emoji_Modifier_Sequence  ; index pointing up                             # E1.0   
  "26F9", //1F3FB    ; RGI_Emoji_Modifier_Sequence  ; person bouncing ball                          # E2.0   
  "270A", //1F3FB    ; RGI_Emoji_Modifier_Sequence  ; raised fist                                   # E1.0   
  "270B", //1F3FB    ; RGI_Emoji_Modifier_Sequence  ; raised hand                                   # E1.0   
  "270C", //1F3FB    ; RGI_Emoji_Modifier_Sequence  ; victory hand                                  # E1.0   
  "270D", //1F3FB    ; RGI_Emoji_Modifier_Sequence  ; writing hand                                  # E1.0   
  "1F385", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; Santa Claus                                   # E1.0   
  "1F3C2", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; snowboarder                                   # E1.0   
  "1F3C3", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person running                                # E1.0   
  "1F3C4", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person surfing                                # E1.0   
  "1F3C7", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; horse racing                                  # E1.0   
  "1F3CA", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person swimming                               # E1.0   
  "1F3CB", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person lifting weights                        # E2.0   
  "1F3CC", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person golfing                                # E4.0   
  "1F442", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; ear                                           # E1.0   
  "1F443", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; nose                                          # E1.0   
  "1F446", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; backhand index pointing up                    # E1.0   
  "1F447", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; backhand index pointing down                  # E1.0   
  "1F448", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; backhand index pointing left                  # E1.0   
  "1F449", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; backhand index pointing right                 # E1.0   
  "1F44A", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; oncoming fist                                 # E1.0   
  "1F44B", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; waving hand                                   # E1.0   
  "1F44C", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; OK hand                                       # E1.0   
  "1F44D", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; thumbs up                                     # E1.0   
  "1F44E", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; thumbs down                                   # E1.0   
  "1F44F", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; clapping hands                                # E1.0   
  "1F450", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; open hands                                    # E1.0   
  "1F466", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; boy                                           # E1.0   
  "1F467", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; girl                                          # E1.0   
  "1F468", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; man                                           # E1.0   
  "1F469", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; woman                                         # E1.0   
  "1F46B", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; woman and man holding hands                   # E12.0  
  "1F46C", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; men holding hands                             # E12.0  
  "1F46D", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; women holding hands                           # E12.0  
  "1F46E", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; police officer                                # E1.0   
  "1F470", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person with veil                              # E1.0   
  "1F471", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person, blond hair                            # E1.0   
  "1F472", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person with skullcap                          # E1.0   
  "1F473", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person wearing turban                         # E1.0   
  "1F474", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; old man                                       # E1.0   
  "1F475", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; old woman                                     # E1.0   
  "1F476", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; baby                                          # E1.0   
  "1F477", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; construction worker                           # E1.0   
  "1F478", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; princess                                      # E1.0   
  "1F47C", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; baby angel                                    # E1.0   
  "1F481", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person tipping hand                           # E1.0   
  "1F482", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; guard                                         # E1.0   
  "1F483", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; woman dancing                                 # E1.0   
  "1F485", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; nail polish                                   # E1.0   
  "1F486", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person getting massage                        # E1.0   
  "1F487", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person getting haircut                        # E1.0   
  "1F48F", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; kiss                                          # E13.1  
  "1F491", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; couple with heart                             # E13.1  
  "1F4AA", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; flexed biceps                                 # E1.0   
  "1F574", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person in suit levitating                     # E4.0   
  "1F575", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; detective                                     # E2.0   
  "1F57A", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; man dancing                                   # E3.0   
  "1F590", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; hand with fingers splayed                     # E1.0   
  "1F595", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; middle finger                                 # E1.0   
  "1F596", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; vulcan salute                                 # E1.0   
  "1F645", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person gesturing NO                           # E1.0   
  "1F646", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person gesturing OK                           # E1.0   
  "1F647", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person bowing                                 # E1.0   
  "1F64B", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person raising hand                           # E1.0   
  "1F64C", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; raising hands                                 # E1.0   
  "1F64D", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person frowning                               # E1.0   
  "1F64E", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person pouting                                # E1.0   
  "1F64F", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; folded hands                                  # E1.0   
  "1F6A3", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person rowing boat                            # E1.0   
  "1F6B4", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person biking                                 # E1.0   
  "1F6B5", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person mountain biking                        # E1.0   
  "1F6B6", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person walking                                # E1.0   
  "1F6C0", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person taking bath                            # E1.0   
  "1F6CC", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person in bed                                 # E4.0   
  "1F90C", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; pinched fingers                               # E13.0  
  "1F90F", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; pinching hand                                 # E12.0  
  "1F918", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; sign of the horns                             # E1.0   
  "1F919", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; call me hand                                  # E3.0   
  "1F91A", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; raised back of hand                           # E3.0   
  "1F91B", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; left-facing fist                              # E3.0   
  "1F91C", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; right-facing fist                             # E3.0   
  "1F91D", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; handshake                                     # E14.0  
  "1F91E", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; crossed fingers                               # E3.0   
  "1F91F", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; love-you gesture                              # E5.0   
  "1F926", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person facepalming                            # E3.0   
  "1F930", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; pregnant woman                                # E3.0   
  "1F931", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; breast-feeding                                # E5.0   
  "1F932", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; palms up together                             # E5.0   
  "1F933", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; selfie                                        # E3.0   
  "1F934", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; prince                                        # E3.0   
  "1F935", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person in tuxedo                              # E3.0   
  "1F936", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; Mrs. Claus                                    # E3.0   
  "1F937", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person shrugging                              # E3.0   
  "1F938", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person cartwheeling                           # E3.0   
  "1F939", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person juggling                               # E3.0   
  "1F93D", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person playing water polo                     # E3.0   
  "1F93E", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person playing handball                       # E3.0   
  "1F977", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; ninja                                         # E13.0  
  "1F9B5", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; leg                                           # E11.0  
  "1F9B6", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; foot                                          # E11.0  
  "1F9B8", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; superhero                                     # E11.0  
  "1F9B9", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; supervillain                                  # E11.0  
  "1F9BB", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; ear with hearing aid                          # E12.0  
  "1F9CD", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person standing                               # E12.0  
  "1F9CE", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person kneeling                               # E12.0  
  "1F9CF", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; deaf person                                   # E12.0  
  "1F9D1", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person                                        # E5.0   
  "1F9D2", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; child                                         # E5.0   
  "1F9D3", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; older person                                  # E5.0   
  "1F9D4", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person, beard                                 # E5.0   
  "1F9D5", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; woman with headscarf                          # E5.0   
  "1F9D6", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person in steamy room                         # E5.0   
  "1F9D7", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person climbing                               # E5.0   
  "1F9D8", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person in lotus position                      # E5.0   
  "1F9D9", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; mage                                          # E5.0   
  "1F9DA", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; fairy                                         # E5.0   
  "1F9DB", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; vampire                                       # E5.0   
  "1F9DC", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; merperson                                     # E5.0   
  "1F9DD", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; elf                                           # E5.0   
  "1FAC3", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; pregnant man                                  # E14.0  
  "1FAC4", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; pregnant person                               # E14.0  
  "1FAC5", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; person with crown                             # E14.0  
  "1FAF0", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; hand with index finger and thumb crossed      # E14.0  
  "1FAF1", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; rightwards hand                               # E14.0  
  "1FAF2", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; leftwards hand                                # E14.0  
  "1FAF3", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; palm down hand                                # E14.0  
  "1FAF4", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; palm up hand                                  # E14.0  
  "1FAF5", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; index pointing at the viewer                  # E14.0  
  "1FAF6", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; heart hands                                   # E14.0  
  "1FAF7", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; leftwards pushing hand                        # E15.0  
  "1FAF8", //1F3FB   ; RGI_Emoji_Modifier_Sequence  ; rightwards pushing hand                       # E15.0  
]

export const EMOJI_CATEGORIES = [
  {
    id: 'emojiFaces',
    hex: {
      ranges: [
        ['1F600','1F64F']
      ]
    },
  },
  {
    id: 'emojiPeople',
    hex: {
      rest: EMOJI_MODIFIER_SEQUENCES,
    },
    modifiers: [
      null, // no modifier - yellow
      '1F3FB', // fitzpatrick type 1-2
      '1F3FC', // fitzpatrick type 3
      '1F3FD', // fitzpatrick type 4
      '1F3FE', // fitzpatrick type 5
      '1F3FF', // fitzpatrick type 6
    ],
  },
  {
    id: 'emojiAnimals',
    hex: {
      ranges: [
        ['1F400','1F43F']
      ]
    },
  },
  {
    id: 'emojiFlags',
    hex: {
      rest: EMOJI_FLAGS
    },
  },
]

/**
 * Space separated unicode hexes eg. "1FAF8 1F3FB" into characters 
 * @param {*} hexString 
 */
const decipherHexStringToEmoji = (hexString) => {
  return hexString
    .split(' ')
    .map( h => String.fromCodePoint( parseInt(h, 16) ))
    .join('') 
}

export const getEmojiList = (category, modifier) => {
  let emojiArray = [];
  
  const spaceModIfMod = modifier ? ' '+modifier : '';
  const ranges = category.hex.ranges
  if (ranges) {
    ranges.forEach( range => {
      const rangeStartInt = parseInt(range[0], 16);
      const rangeEndInt = parseInt(range[1], 16);
      const rangeCount = rangeEndInt - rangeStartInt;
      const rangeEmojis = Array
        .from({length:rangeCount})
        .map((_a,i) => rangeStartInt+i)
        .map( code => decipherHexStringToEmoji( code.toString(16) + spaceModIfMod ));
      emojiArray = emojiArray.concat(...rangeEmojis);
    })
  }

  const rest = category.hex.rest?.map( 
      hex => decipherHexStringToEmoji(hex + spaceModIfMod)
    ) || [];

  return emojiArray.concat(rest);
}