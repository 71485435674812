import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import HearingMap from './HearingMap';

const CommentGeoMapComponent = ({
                                  data,
                                  toggleMap,
                                  displayMap,
                                  handleSetMapContainer,
                                  mapContainer,
                                }) => {
  return (
    <>
      {data && (
        <div className="hearing-comment__map">
          <>
            <Button
              onClick={toggleMap}
              className="hearing-comment__map-toggle"
              aria-expanded={displayMap}
            >
              <FormattedMessage id="commentShowMap">
                {text => text}
              </FormattedMessage>
            </Button>
          </>
          {displayMap && data && (
            <div
              className="hearing-comment__map-container"
              ref={handleSetMapContainer}
            >
              {data && (
                <HearingMap
                  hearing={{ geojson: data }}
                  mapContainer={mapContainer}
                  mapSettings={{ dragging: false }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
CommentGeoMapComponent.propTypes = {
  data: PropTypes.object,
  toggleMap: PropTypes.func,
  handleSetMapContainer: PropTypes.func,
  displayMap: PropTypes.bool,
  mapContainer: PropTypes.object,
};
export default CommentGeoMapComponent;