import PropTypes from 'prop-types';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, styled } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommentEditMenuButton from './CommentEditMenuButton';
import useWindowSize from '../../../../../common/useWindowSize';
import getMessage from '../../../../../utils/getMessage';

/**
 * Renders edit and delete buttons for own comments.
 */

const StyledButton = styled(IconButton)`
    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }
`;


const CommentEditButtons = ({ handleEdit, handleDelete }) => {
  const { width } = useWindowSize();

  return (
    <div className="hearing-comment-actions">
      {width > 600 ?
        (<>
            <StyledButton aria-label="pensil" className="button" title={getMessage('edit')} onClick={handleEdit}>
              <EditIcon fontSize="large" />
              <FormattedMessage id='edit'/>
            </StyledButton>
            <StyledButton aria-label="trash" className="btn--delete" title={getMessage('delete')} onClick={handleDelete}>
              <DeleteIcon fontSize="large" />
              <FormattedMessage id='delete'/>
            </StyledButton>
          </>
        )
        : <CommentEditMenuButton
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />}

    </div>
  );
};

CommentEditButtons.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default injectIntl(CommentEditButtons);