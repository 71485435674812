import { getUser } from '/src/store/selectors/user';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import React from 'react';
import getMessage from '/src/utils/getMessage';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { generateRandomString } from '/src/utils/common';
import styled from 'styled-components';
import ProfileImage from './ProfileImage';

const StyledCell = styled(TableCell)`
    font: normal normal normal 16px PT Sans;
    word-break: break-all;
`;

const TableWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 610px) {
        justify-content: center;
        flex-direction: row;
        width: 100%;
    }
`;

const ImageWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    @media (max-width: 610px) {
        justify-content: center;
        flex-direction: row;
        margin-right: 0;
    }
`;

const MainStyledDiv = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 610px) {
        flex-direction: column;
    }
`;


const UserMainInfoPanel = ({ user }) => {
  const info = [
    {
      name: getMessage('userNickname'),
      value: user?.nickname ?? '',
    },
    {
      name: getMessage('username'),
      value: user?.username ?? '',
    },
    {
      name: getMessage('firstName'),
      value: user?.firstname ?? '',
    },
    {
      name: getMessage('lastName'),
      value: user?.lastname ?? '',
    },
    {
      name: getMessage('email'),
      value: user?.email ?? '',
    },
    {
      name: getMessage('adminOrganizations'),
      value: user?.adminOrganizations?.join(',') ?? '',
    },
  ];

  const addUserOidToInfoTableIfExists = () => {
    // Duplicate check likely unnecessary, but just in case
    if (user?.oid && !info.find( row => row.name == 'OID' )) {
      info.push({
        name: 'OID',
        value: user.oid,
      });
    }
  };
  addUserOidToInfoTableIfExists();

  return (
    <MainStyledDiv>
      <ImageWrapperDiv>
        <ProfileImage />
      </ImageWrapperDiv>

      <TableWrapperDiv>
        <Table
          sx={{
            minWidth: 320,
            tableLayout: 'fixed',
          }}
          aria-label={getMessage('userMainInfoTab')}
        >
          <TableBody>
            {info.map(item =>
              <TableRow
                key={`table_row_${generateRandomString()}`}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <StyledCell>{item.name}</StyledCell>
                <StyledCell>{item.value}</StyledCell>
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </TableWrapperDiv>


    </MainStyledDiv>
  )
    ;
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

UserMainInfoPanel.propTypes = {
  user: PropTypes.object,
};

export default injectIntl(connect(mapStateToProps)(UserMainInfoPanel));
