import React, { useEffect } from 'react';
import { cleanJumpTo, editSectionComment } from '../../../../../store/actions';
import { getUser } from '../../../../../store/selectors/user';
import PropTypes from 'prop-types';
import useComment from './useComment.hook';
import classnames from 'classnames';
import Sticker from './Sticker';
import nl2br from 'react-nl2br';
import CommentImageComponent from './CommentImageComponent';
import CommentGeoMapComponent from './CommentGeoMapComponent';
import CommentFooterComponent from './CommentFooterComponent';
import { connect } from 'react-redux';
import {
  removeEditingReply,
  setIsEditingReply,
} from '../../../../../store/actions/commentEditorActions';
import {
  getCommentEditingStateById,
  getReplyEditingTextById,
} from '../../../../../store/selectors/commentEditorSelectors';
import IntersectionObserverContainer from './IntersectionObserverContainer';
import { FormattedMessage } from 'react-intl';

const Comment = ({
                   canReply,
                   canVote,
                   data,
                   defaultNickname,
                   hearing,
                   hearingId,
                   isReply,
                   jumpTo,
                   nicknamePlaceholder,
                   onDeleteComment,
                   onUnPublishComment,
                   onPublishComment,
                   onPostReply,
                   onPostVote,
                   questions,
                   sectionCommentId,
                   section,
                   user,
                   language,
                   onEditComment,
                   getCommentSubComments,
                   removeUrlFocusComment,
                   isEditing,
                   setIsEditingReply,
                   replayingText,
                   removeEditingReply,
                   newCommentParentChain = [],
                   newCommentIds = [],
                   handleCommentWasSeen,
                   cleanJumpTo,
                 }) => {
  const {
    isAdminUser,
    onVote,
    setPinned,
    setAnswers,
    editorOpen, setEditorOpen,
    shouldJumpTo, setShouldJumpTo,
    setShouldAnimate,
    scrollComplete, setScrollComplete,
    isRepliesOpen, setIsRepliesOpen,
    isReplyEditorOpen, setIsReplyEditorOpen,
    mapContainer, setMapContainer,
    displayMap, setDisplayMap,
    handleToggleReplyEditor,
    renderCommentHeader,
    renderEditorForm,
    renderEditLinks,
    renderRemoveLink,
    renderReplyForm,
    renderViewReplyButton,
    handleSetMapContainer,
    toggleMap,
    shouldRenderDeleteForOthers,
    commentParagraphClass,
    renderHideReplyButton,
  } = useComment();

  const commentRef = React.createRef();

  const init = () => {
    if (
      shouldJumpTo &&
      commentRef &&
      commentRef.current &&
      !scrollComplete
    ) {
      commentRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'center',
        alignToTop: true,
      });
      cleanJumpTo(section.id);
      setScrollComplete(true);
      setShouldAnimate(true);
      removeUrlFocusComment();
    }
  };

  useEffect(() => {
    init();
  }, [shouldJumpTo]);

  useEffect(() => {
    setEditorOpen(isEditing);
  }, [isEditing]);

  useEffect(() => {
    setEditorOpen(isEditing);
    setIsRepliesOpen(data.subComments);
    setIsReplyEditorOpen(!!replayingText);
    setShouldJumpTo(jumpTo === data.id);
    setScrollComplete(false);
    setShouldAnimate(false);
    setPinned(data.pinned);
    setAnswers(data.answers || []);
    setMapContainer(null);
    setDisplayMap(false);
  }, []);


  /**
   * Renders the sub comments
   * @returns {Component<Comment>} recursively renders comment component until last depth.
   */
  const renderSubComments = () => {
    return (
      <ul className="sub-comments">
        {data.subComments.map(subComment => (
            <ConnectedComment
              canReply={canReply}
              canVote={canVote}
              defaultNickname={defaultNickname}
              hearing={hearing}
              hearingId={hearingId}
              isReply={isReply}
              jumpTo={jumpTo}
              nicknamePlaceholder={nicknamePlaceholder}
              onDeleteComment={onDeleteComment}
              onUnPublishComment={onUnPublishComment}
              onPublishComment={onPublishComment}
              onPostReply={onPostReply}
              onPostVote={onPostVote}
              questions={questions}
              sectionCommentId={sectionCommentId}
              section={section}
              user={user}
              language={language}
              onEditComment={onEditComment}
              getCommentSubComments={getCommentSubComments}
              removeUrlFocusComment={removeUrlFocusComment}
              parentComponentId={data.id}
              data={subComment}
              key={`${subComment.id}${Math.random()}`}
              setIsEditingReply={setIsEditingReply}
              removeEditingReply={removeEditingReply}
              isEditing={isEditing}
              newCommentParentChain={newCommentParentChain}
              newCommentIds={newCommentIds}
              handleCommentWasSeen = {handleCommentWasSeen}
            />
          ),
        )
        }
      </ul>
    );
  };

  const canEdit = data.can_edit;

  const hasSubComments = data.subComments &&
    Array.isArray(data.subComments) &&
    data.subComments.length > 0;

  const isOwnComment =
    data &&
    user &&
    (data.author_name === user.nickname ||
      data.author_name === user.username);

  const _setIsEditingReply = (value) => setIsEditingReply(data.id, section.id, value);
  const _cancelReplyEditing = () => removeEditingReply(data.id, section.id);

  const _renderEditorForm = () => renderEditorForm(data, isReply, sectionCommentId, hearing, onEditComment, removeUrlFocusComment, section);
  const _renderReplyForm = () => renderReplyForm(
    canReply,
    defaultNickname,
    hearingId,
    language,
    user,
    nicknamePlaceholder,
    section,
    sectionCommentId,
    onPostReply,
    data,
    _setIsEditingReply,
    replayingText,
    _cancelReplyEditing,
  );
  const isInNewCommentChain = newCommentParentChain.includes(data.id);
  const _renderViewReplyButton = () => renderViewReplyButton(data, section, sectionCommentId, getCommentSubComments, isInNewCommentChain);
  const _renderEditLinks = () => renderEditLinks(data, onDeleteComment, onUnPublishComment, onPublishComment);

  const onEnteringIntoView = () => {
    if (newCommentIds.includes(data.id)) {
      handleCommentWasSeen(data.id);
    }
  }

  const hearingCommentBody = (data) => {
    if (data.deleted) {
      return (<div className="hearing-comment-body deleted-comment">
        <span><FormattedMessage id="deletedCommentContent" /></span>
      </div>)
    }
    return (
    <div className="hearing-comment-body">
      <p className={commentParagraphClass(data)}>{nl2br(data.content)}</p>
    </div>)
  }

  return (
    !data.content
      ? <></>
      : (
        <li
          data-aos-once="true"
          className={classnames([
            'hearing-comment',
            {
              'comment-reply': isReply,
              'hearing-comment__has-replys': hasSubComments,
              'hearing-comment__admin': isAdminUser(),
              'hearing-comment__own': isOwnComment,
              'hearing-comment__is-pinned': data.pinned,
              'hearing-comment__unpublished': !data.published,
              'hearing-comment__is_new_comment': newCommentIds.includes(data.id),
            },
          ])}
          ref={commentRef}
        >
          <div className="hearing-comment__comment-wrapper">
            {renderCommentHeader(isOwnComment, data, onDeleteComment, newCommentIds)}
            <Sticker stickerId={data.sticker} />
            {
              newCommentIds.includes(data.id)
              ? (
                <IntersectionObserverContainer id={data.id} onEnteringIntoView={onEnteringIntoView} delay={5000}>
                  {hearingCommentBody(data)}
                </IntersectionObserverContainer>
              )
              : (
                <>{hearingCommentBody(data)}</>
              )
            }
            <CommentImageComponent images={data.images} />
            <CommentGeoMapComponent
              data={data.geojson}
              displayMap={displayMap}
              handleSetMapContainer={handleSetMapContainer.bind(this)}
              mapContainer={mapContainer}
              toggleMap={toggleMap}
            />
            {canEdit && !isOwnComment && _renderEditLinks()}
            {shouldRenderDeleteForOthers(data, user, hearing)
              && renderRemoveLink(data, onDeleteComment, onUnPublishComment, onPublishComment)}
            <CommentFooterComponent
              canReply={!isReplyEditorOpen && canReply && !data.deleted}
              votesNum={data.n_votes}
              handleToggleReplyEditor={(e) => {
                handleToggleReplyEditor(e);
              }}
              onVote={() => onVote(canVote, onPostVote, data, sectionCommentId)}
              showVotes={!data.deleted}
            />
            {editorOpen && _renderEditorForm()}
            {isReplyEditorOpen && _renderReplyForm()}
            {isRepliesOpen
              ? renderHideReplyButton(data, isInNewCommentChain)
              : _renderViewReplyButton()}
          </div>
          {hasSubComments &&
            isRepliesOpen &&
            renderSubComments()}
        </li>
      )
  );
};


const mapDispatchToProps = dispatch => ({
  onEditComment: (hearingSlug, sectionId, commentId, sectionCommentId, commentData) =>
    dispatch(
      editSectionComment(hearingSlug, sectionId, commentId, sectionCommentId, commentData),
    ),
  setIsEditingReply: (commentId, sectionId, value) =>
    dispatch(
      setIsEditingReply(commentId, sectionId, value),
    ),
  removeEditingReply: (commentId, sectionId) =>
    dispatch(
      removeEditingReply(commentId, sectionId),
    ),
  cleanJumpTo: (sectionId) =>
    dispatch(cleanJumpTo(sectionId)),
});

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  language: state.language,
  isEditing: getCommentEditingStateById(state, ownProps.data.id, ownProps.section.id),
  replayingText: getReplyEditingTextById(state, ownProps.data.id, ownProps.section.id),
});

Comment.propTypes = {
  canReply: PropTypes.bool,
  canVote: PropTypes.bool,
  data: PropTypes.object,
  defaultNickname: PropTypes.string,
  hearing: PropTypes.object,
  hearingId: PropTypes.string,
  isReply: PropTypes.bool,
  jumpTo: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  nicknamePlaceholder: PropTypes.string,
  onDeleteComment: PropTypes.func,
  onUnPublishComment: PropTypes.func,
  onPublishComment: PropTypes.func,
  onPostReply: PropTypes.func,
  onPostVote: PropTypes.func,
  parentComponentId: PropTypes.number,
  questions: PropTypes.array,
  sectionCommentId: PropTypes.number,
  section: PropTypes.object,
  user: PropTypes.object,
  language: PropTypes.string,
  onEditComment: PropTypes.func,
  getCommentSubComments: PropTypes.func,
  removeUrlFocusComment: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsEditingReply: PropTypes.func,
  replayingText: PropTypes.string,
  removeEditingReply: PropTypes.func,
  newCommentParentChain: PropTypes.array,
  newCommentIds: PropTypes.array,
  handleCommentWasSeen: PropTypes.func,
  cleanJumpTo: PropTypes.func,
};

Comment.defaultProps = {
  isReply: false,
};

export { Comment as UnconnectedComment };
const ConnectedComment = connect(mapStateToProps, mapDispatchToProps)(Comment);
export default ConnectedComment;

Comment.displayName = 'Comment';