import PropTypes from 'prop-types';
import CommentEditButtons from './CommentEditButtons';
import classnames from 'classnames';
import { FormattedMessage, FormattedRelative } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';
import { DateTime } from 'luxon';
import Icon from '../../../../../utils/Icon';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const CommentHeaderComponent = ({
                                  handleDelete,
                                  toggleEditor,
                                  isOwnComment,
                                  isAdminUser,
                                  commentData,
                                  isNewComment,
                                }) => {


  /**
   * User DateTime to convert current timestamp to desired format.
   * @returns {String}
   */
  const parseTimestamp = (timestamp) => DateTime.fromISO(timestamp).toFormat('dd.MM.yyyy HH:mm');

  const dateTooltip =
    <Tooltip id="comment-date-tooltip">
      {parseTimestamp(commentData.created_at)}
    </Tooltip>;

  return (
    <div className="hearing-comment-header clearfix">
      {commentData.pinned && (
        <div className="hearing-comment-pinned-container">
          <FormattedMessage id="pinnedComment" />
        </div>
      )}
      {!commentData.published &&
        <FormattedMessage id="unpublished" />
      }
      <div className="hearing-comment-publisher">
        <div className='hearing-author-and-date'>
          <div
            className={classnames({
              'hearing-comment-user': true,
              'hearing-comment-user-own': isOwnComment,
            })}
          >
            {commentData.is_registered ? (
              <span
                className={classnames({
                  'hearing-comment-user-registered': !isAdminUser,
                  'hearing-comment-user-organization': isAdminUser,
                  'hearing-comment-user-own': isOwnComment,
                })}
              >
                <Icon name="user" aria-hidden="true" />
                &nbsp;
                {isAdminUser && (<>
                  {commentData.organization}:&nbsp; </>)}
              </span>
            ) : null}
            {commentData.author_name || <FormattedMessage id="anonymous" />}
          </div>
          <OverlayTrigger
            placement="top"
            overlay={dateTooltip}
            delayShow={300}
          >
            <div className="hearing-comment-date">
              <FormattedRelative value={commentData.created_at} />
            </div>
          </OverlayTrigger>
        </div>
        <div style={{ display: 'flex' }}>
        {
            isNewComment && (
              <div className='hearing-comment-new-width-container'>
                <div className='hearing-comment-absolute-container'>
                  <NewReleasesIcon sx={{
                    color: '#ffd54f',
                    width: '34.999',
                    height: '34.999',
                    marginLeft: '5px'
                  }} />
                </div>
              </div>
            )
          }
          {isOwnComment && commentData.can_edit && <CommentEditButtons
            handleEdit={toggleEditor}
            handleDelete={handleDelete}
          />}
        </div>
      </div>
    </div>
  );
};

CommentHeaderComponent.propTypes = {
  toggleEditor: PropTypes.func,
  handleDelete: PropTypes.func,

  isAdminUser: PropTypes.bool,
  isOwnComment: PropTypes.bool,
  commentData: PropTypes.object,
  isNewComment: PropTypes.bool,
};

export default CommentHeaderComponent;