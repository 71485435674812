import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Icon from '../../../../../utils/Icon';

const CommentFooterComponent = ({
                                  canReply,
                                  votesNum,
                                  handleToggleReplyEditor,
                                  onVote,
                                  showVotes = true
                                }) => {

  /**
   * If a thread can be replied to, render reply links
   */
  const renderReplyLinks = () => (
    <>
      <Icon name="reply" />
      <a
        href=""
        style={{ marginLeft: 6, fontWeight: 'bold' }}
        onClick={handleToggleReplyEditor}
      >
        <FormattedMessage id="reply" />
      </a>
    </>
  );

  return (
    <div className="hearing-comment__actions-bar">
      <div className="hearing-comment__reply-link">
        {canReply && renderReplyLinks()}
      </div>
      { showVotes &&
      <div className="hearing-comment-votes">
        <Button
          className="btn-sm hearing-comment-vote-link"
          onClick={onVote}
        >
          <Icon name="thumbs-o-up" aria-hidden="true" /> {votesNum}
          <span className="sr-only">
            <FormattedMessage id="voteButtonLikes" />
            <FormattedMessage id="voteButtonText" />
          </span>
        </Button>
      </div>
      }
    </div>
  );
};

CommentFooterComponent.propTypes = {
  onVote: PropTypes.func,
  handleToggleReplyEditor: PropTypes.func,

  canReply: PropTypes.bool,
  votesNum: PropTypes.number,
};

export default CommentFooterComponent;