import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../utils/Icon';

const CloseButton = ({ closeToast }) => (
  <Icon name="times" onClick={closeToast} />
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

export default CloseButton;
