
/**
 * For consistency and to rid the world of magic strings, 
 * eg. const a = buttonKey + '-' + i; // looks bad, kinda mystery in purpose
 * vs. buttonKeyId(buttonKey,i);
 * @param {*} buttonKey 
 * @param {*} index 
 * @returns 
 */
export const buttonKeyId = (buttonKey, index) => {
  return `${buttonKey}-${index}`
}