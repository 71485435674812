import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import EmojiSelector from "./EmojiSelector";
import StickerSelector from "./StickerSelector";

const DigiraatiEmojiInput = ({emojiSelectionHandler, stickerSelectionHandler, selectedSticker}) => {
  const [open, setOpen] = useState(null)
  const stickersEnabled = false;

  const toggleOpen = (tab) => {
    if (open == tab) setOpen(null)
    else setOpen(tab);
  }  

  const selectedTab = (predicate) => (
    predicate ? 'selected' : null
  )

  return (
    <div style={{display:'grid', gridTemplateColumns: 'max-content 1fr'}}>
      <div className='comment-tools'>
        <Button id='button-emojis' 
          bsStyle="default" 
          onClick={() => toggleOpen('emojis')}
          className={selectedTab(open == 'emojis')}
        >
          <FormattedMessage id="emojis" />
        </Button>
        {stickersEnabled &&
          <Button id="button-stickers"
            bsStyle="default"
            onClick={() => toggleOpen('stickers')}
            className={selectedTab(open == 'stickers')}
          >
            <FormattedMessage id="stickers" />
          </Button>
        }
      </div>
      <div>
        {
          open == null &&
          <div>
            <p>
              <FormattedMessage id='commentToolsDescription' />
            </p>
            <ul>
              <li>
                <FormattedMessage id='commentToolsDescriptionEmojiMobile' />
              </li>
              <li>
                <FormattedMessage id='commentToolsDescriptionEmojiDesktop' />:<>&nbsp;</>
                <kbd><>&#8862;</> Win</kbd> + <kbd>.</kbd>
              </li>
            </ul>
          </div>
        }
        { open == 'emojis' &&
          <EmojiSelector selectionHandler={emojiSelectionHandler} />
        }
        {
          open == 'stickers' &&
          <StickerSelector selectionHandler={stickerSelectionHandler} selectedSticker={selectedSticker}/>
        }
      </div>
    </div>
  )
}

export default DigiraatiEmojiInput;