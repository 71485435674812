import React from 'react';
import { injectIntl } from 'react-intl';
import { COMMENT_STICKERS, COMMENT_STICKERS_PATH } from '../../../../../data/commentStickers';

const Sticker = ({ stickerId, intl }) => {

  const sticker = COMMENT_STICKERS.find(
    sticker => sticker.id === stickerId,
  );

  if (!sticker) {
    return (
      <></>
    );
  }

  return (
    <img className="comment-sticker"
         src={`${COMMENT_STICKERS_PATH}${sticker.src}`}
         alt={sticker.alt[intl.locale]}
    />
  );
};

export default injectIntl(Sticker); // injectIntl adds intl to props so we can access intl.locale