import styled from 'styled-components';
import { Form } from 'formik';

export const FormWrapperDiv = styled.div`
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.33);
    width: 50%;

    @media (max-width: 800px) {
        width: 100%;
    }
`;

export const StyledForm = styled(Form)`
    display: flex;
    padding: 20px;
    font: normal normal normal 18px PT Sans;
    flex-direction: column;

    div {
        display: flex;
        width: 100%;
        color: #212121;
        flex-direction: column;
        margin-bottom: 10px;
        outline: none;
    }

    .button-wrap {
        align-items: center;
    }

    label {
        margin-bottom: 0;
        font: normal normal normal 18px PT Sans;
    }

    p {
        font: normal normal normal 14px PT Sans;
    }
`;