/* eslint-disable import/prefer-default-export */
export function isUrl(string) {
  try { return Boolean(new URL(string)); } catch (error) { return false; }
}

/**
 * turns eg. "A user with that username already exists."
 * to "errorResponseAUserWithThatUsernameAlreadyExists"
 */
export function turnResponseErrorStringToLocalizationKey(responseErrorString) {
  const localizationPrefix= "errorResponse"
  const toAlphanumericCamelCase = (str) => (
    str
      .toLowerCase()
      .split(' ')
      .map((word) => ( word.slice(0, 1).toUpperCase().concat(word.slice(1))))
      .join('')
      .replaceAll(/[^a-zA-Z0-9]/g, '')
  )
  return `${localizationPrefix}${toAlphanumericCamelCase(responseErrorString)}`;
}

/**
 * turns eg. { "username": ["A user with that username already exists."] }
 * to { "username": ["errorResponseAUserWithThatUsernameAlreadyExists"] }
 */
export function responseBodyToResponseErrorLocalizationKeys(responseBody) {
  return Object.keys(responseBody).reduce(
    (prev, cur) => ({
      ...prev, 
      [cur]: responseBody[cur].map( err => turnResponseErrorStringToLocalizationKey(err) )
    }), 
    {}
  );
}