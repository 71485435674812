import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from 'react-bootstrap';
import { IconButton, ListItemIcon, Menu, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';

const StyledButton = styled(IconButton)`
    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }
`;

const StyledMenu = styled(Menu)`
    margin-top: 0.3em;

    li {
        display: flex;
        margin-bottom: 0.3em;

        a {
            display: flex;

            .MuiSvgIcon-root {
                margin-left: 1em;
            }
        }
    }
`;

const CommentEditMenuButton = ({ handleEdit, handleDelete }) =>{
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledButton aria-label="Menu"
                    className="button"
                    onClick={handleClick}>
        <MoreVertIcon fontSize="large" />
      </StyledButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          root: {
            sx: {
              overflowY: "scroll",
              overflowX: "hidden"
            }
          },
          paper: {
            sx: {
              width: '250px',
              maxWidth: '35%',
              left: '10px',
              right: '0px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            },
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="large" />
          </ListItemIcon>
          <FormattedMessage id="edit" />
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="large" />
          </ListItemIcon>
          <FormattedMessage id="delete" />
        </MenuItem>
      </StyledMenu>
    </>
  );
}

CommentEditMenuButton.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default injectIntl(CommentEditMenuButton);