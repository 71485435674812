import { Field, Formik } from 'formik';
import { FormHelperText } from '@mui/material';
import { object, ref, string } from 'yup';
import React from 'react';
import getMessage from '../../utils/getMessage';
import DigiraatiButton from '../../common/DigiraatiButton';
import { FormWrapperDiv, StyledForm } from './PasswordChangeInterfaces';
import { localizedNotifyError, localizedNotifySuccess } from '../../utils/notify';
import api from '../../api';

const PasswordChangePanel = () => {

  const _handleSubmit = async (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    try {
      const data = {
        new_password1: values.newPass,
        new_password2: values.confirmPass,
        old_password: values.currentPass,
      };
      const result = await api
        .post({}, `/v1/auth/password/change/`, data);
      setSubmitting(false);
      if (result.status !== 200) {
        localizedNotifyError('passwordResetFailed');
      } else {
        resetForm();
        localizedNotifySuccess('passwordResetSuccess');
      }
    } catch (e) {
      setSubmitting(false);
      localizedNotifyError('passwordResetFailed');
      throw new Error(e);
    }
  };

  const formValidationSchema = object().shape({
    currentPass: string().required('required'),
    newPass: string().required('required').min(6, 'registrationFailedShortPassword'),
    confirmPass: string()
      .oneOf([ref('newPass')], 'passwordMismatch')
      .required('required'),
  });


  return (
    <div>
      <Formik
        initialValues={{
          currentPass: '',
          newPass: '',
          confirmPass: '',
        }}
        validationSchema={formValidationSchema}
      >
        {({
            touched,
            dirty,
            errors,
            values,
            resetForm,
            isValid,
            handleChange,
            setSubmitting,
            isSubmitting,
          }) => {
          return (
            <FormWrapperDiv>
              <StyledForm autoComplete="off" className="hds-form hds-form--login">
                <div className="hds-text-input">
                  <label htmlFor="currentPass">{getMessage('currentPassword')}*</label>
                  <Field
                    required
                    id="currentPass"
                    name="currentPass"
                    class="form-control"
                    type="password"
                    autocomplete="off"
                    disabled={isSubmitting}
                  />
                  <FormHelperText
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {touched.currentPass && errors.currentPass
                      ? getMessage(errors.currentPass)
                      : ' '}
                  </FormHelperText>
                </div>
                <div className="hds-text-input">
                  <label htmlFor="newPass">{getMessage('newPassword')}*</label>
                  <Field
                    required
                    id="newPass"
                    name="newPass"
                    class="form-control"
                    type="password"
                    autocomplete="off"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                  <FormHelperText
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {touched.newPass && errors.newPass
                      ? getMessage(errors.newPass)
                      : ' '}
                  </FormHelperText>
                </div>
                <div className="hds-text-input">
                  <label htmlFor="confirmPass">{getMessage('confirmPassword')}*</label>
                  <Field
                    required
                    id="confirmPass"
                    name="confirmPass"
                    class="form-control"
                    type="password"
                    autocomplete="off"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                  <FormHelperText
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {touched.confirmPass && errors.confirmPass
                      ? getMessage(errors.confirmPass)
                      : ' '}
                  </FormHelperText>
                </div>
                <div className="button-wrap">
                  <button
                    onClick={() => _handleSubmit(values, setSubmitting, resetForm)}
                    className="digi-button"
                    buttonLabel={getMessage('resetPassword')}
                    disabled={Boolean(!dirty || !isValid || isSubmitting)}
                    style={{ margin: '16px' }}
                    isLoading={isSubmitting}
                  > {getMessage('resetPassword')}</button>
                </div>
              </StyledForm>

            </FormWrapperDiv>
          );
        }}
      </Formik>
    </div>);
};

export default PasswordChangePanel;