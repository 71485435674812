import config from '../config';
import { useEffect } from 'react';

export const OIDCCallBack = ({ history }) => {

  const getToken = async () => {
    const queryParams = window.location.search;
    await fetch(`${config.apiBaseUrl}/oidc/callback/${queryParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => { if (responseJson.token) localStorage.setItem('token', responseJson.token)})
      .then(() => window.open('/', '_self'));
  };
  useEffect( () => {
    getToken();
  }, []);

  return (
    <>
    </>
  )
}