import PropTypes from 'prop-types';
import getAttr from '../../utils/getAttr';
import MouseOnlyLink from '../MouseOnlyLink';
import { getHearingURL, isPublic } from '../../utils/hearing';
import Link from '../LinkWithLang';
import { FormattedMessage } from 'react-intl';
import Icon from '../../utils/Icon';
import { IconButton } from '@mui/material';
import { StyledBadge } from './HearingCard';
import hearingJoinRequestSvg from '../../../assets/images/hearing-join-request.svg';
import FormatRelativeTime from '../../utils/FormatRelativeTime';
import getMessage from '../../utils/getMessage';
import LabelList from '../LabelList';
import { Label } from 'react-bootstrap';
import config from '../../config';
import React from 'react';
import { withRouter } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import defaultImage from '@city-images/default-image.svg';
import HearingJoinRequestInfo from './HearingJoinRequestInfo';
import nl2br from 'react-nl2br';

const HearingListItem = ({ hearing, language, history, listId }) => {
  const getFinalStatementPreviewURL = () => {
    return '/statement/preview/' + hearing.final_statement.id;
  };

  const mainImage = hearing.main_image;

  let mainImageStyle = {
    backgroundImage: `url(${defaultImage})`,
  };

  if (hearing.main_image) {
    mainImageStyle = {
      backgroundImage: 'url("' + mainImage.url + '")',
    };
  }

  const translationAvailable = !!getAttr(hearing.title, language, { exact: true });
  const availableInLanguageMessages = {
    fi: 'Kuuleminen saatavilla suomeksi',
    sv: 'Hörandet tillgängligt på svenska',
    en: 'Questionnaire available in English',
  };
  return (
    <div className="hearing-list-item" role="listitem">
      {hearing.can_access_detail ?
        <MouseOnlyLink
          className="hearing-list-item-image"
          style={mainImageStyle}
          history={history}
          url={getHearingURL(hearing)}
          altText={getAttr(hearing.title, language)}
        />
        :
        <div className="hearing-list-item-image" style={mainImageStyle} />
      }
      <div className="hearing-list-item-content">
        <div className="hearing-list-item-title-wrap">
          <div className="hearing-list-item-title-column">
            <h3 className="h4 hearing-list-item-title">
              {hearing.can_access_detail ?
                <Link to={{ path: getHearingURL(hearing) }}>
                  {!isPublic(hearing) ? (
                    <FormattedMessage id="hearingListNotPublished">
                      {(label) => <Icon name="eye-slash" aria-label={label} />}
                    </FormattedMessage>
                  ) : null}{' '}
                  {getAttr(hearing.title, language)}
                </Link>
                :
                getAttr(hearing.title, language)
              }
            </h3>
            <HearingJoinRequestInfo hearing={hearing} listId={listId} />
          </div>
          <div className="hearing-list-item-comments">
            <Icon name="comment-o" aria-hidden="true" />&nbsp;{hearing.n_comments}

            {hearing.can_access_detail && hearing.active_join_requests_num > 0 &&
              <div style={{ display: 'flex', alignItems: 'start' }}>
                <IconButton
                  className="activeJoinRequestNotifications"
                  aria-label="active join requests notifications"
                  style={{ marginLeft: '20px', paddingTop: '0' }}
                  onClick={() => history.push({
                    pathname: `/${hearing.slug}/manage/hearing-join-requests`,
                  })}
                >
                  <StyledBadge badgeContent={hearing.active_join_requests_num} color="warning"
                               max={99}>
                    <img
                      className="hearing-image"
                      src={hearingJoinRequestSvg}
                    />
                  </StyledBadge>
                </IconButton>
              </div>
            }

            <span className="sr-only">
                {hearing.n_comments === 1 ? (
                  <FormattedMessage id="hearingListComment" />
                ) : <FormattedMessage id="hearingListComments" />}
              </span>
          </div>
        </div>
        <div>
          <p className="hearing-organization">{hearing.organization}</p>
          <p>
            { nl2br( getAttr(hearing.abstract, language) ) }
          </p>
        </div>
        <div className="hearing-list-item-times">
          <div>
            <FormatRelativeTime messagePrefix="timeOpen" timeVal={hearing.open_at} />
          </div>
          <div>
            <FormatRelativeTime messagePrefix="timeClose" timeVal={hearing.close_at} />
          </div>
          {hearing.final_statement && hearing.final_statement.published &&
            <a href={getFinalStatementPreviewURL()} target="_blank" rel="noopener noreferrer"
               className="final-statement-preview-link">
              {getMessage('finalStatementPreview')}
            </a>
          }
        </div>
        <div className="hearing-list-item-labels clearfix">
          <LabelList labels={hearing.labels} className="hearing-list-item-labellist"
                     language={language} />
          {hearing.closed ? (
            <div className="hearing-list-item-closed">
              <Label>
                <FormattedMessage id="hearingClosed" />
              </Label>
            </div>
          ) : null}
        </div>
        {!translationAvailable && (
          <div className="hearing-card-notice">
            <Icon name="exclamation-circle" aria-hidden="true" />
            <FormattedMessage id="hearingTranslationNotAvailable" />
            {config.languages.map(
              lang =>
                (getAttr(hearing.title, lang, { exact: true }) ? (
                  <div key={lang} className="language-available-message" lang={lang}>
                    {availableInLanguageMessages[lang]}
                  </div>
                ) : null),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
HearingListItem.propTypes = {
  hearing: PropTypes.object.isRequired,
  language: PropTypes.string,
  history: PropTypes.object.isRequired,
  listId: PropTypes.string,
};

export default withRouter(HearingListItem);