import React, { useEffect, useRef, useState } from "react";
import { buttonKeyId } from "../utils/emojiUtils";

/**
 * Container element for keyboard arrow key navigable button grids.
 * Should contain buttons with enumerated id values as defined by the
 * buttonKeyId function.
 * Escape key will move focus to an element with the escapeId id.
 * @param {*} param0 
 * @returns 
 */
const ArrowNavigationGrid = ({children, buttonKey, escapeId, max, maxContent='max-content'}) => {
  const FALLBACK_ROWSIZE = 5;
  const [rowSize, setRowSize] = useState(FALLBACK_ROWSIZE) // used for keyboard arrow movement

  const containerRef = useRef();

  const getRowSize = () => {
    if (!containerRef.current) return FALLBACK_ROWSIZE;
    const buttons = containerRef.current.querySelectorAll(`.${buttonKey}`)
    // offsetLeft comparison at row change is easier than width calculation
    let index = 0, lastOffset = -1;
    for ( ; index < buttons.length; ++index ) {
      const button = buttons[index]
      if (button.offsetLeft < lastOffset) {
        break;
      }
      lastOffset = button.offsetLeft
    }
    setRowSize(index ? index : FALLBACK_ROWSIZE);
  }

  useEffect(() => {
    window.addEventListener("resize", getRowSize);
    getRowSize()

    return () => window.removeEventListener("resize", getRowSize);
  }, [])

  
  const keyDownHandler = (event) => {
    const code = event.nativeEvent.code;
    // The buttons have a set id format and enumeration which can be used 
    // to identify whether activeElement is one of the buttons and its index
    const focusedIndex = document.activeElement.id?.startsWith(buttonKey)
      ? parseInt(document.activeElement.id.slice(buttonKey.length+1))
      : 0;
    
    const arrowTargets = {
      "ArrowDown": () => Math.min(focusedIndex+rowSize, max), // row down
      "ArrowUp": () => Math.max(focusedIndex-rowSize, 0),     // row up
      "ArrowLeft": () => Math.max(focusedIndex-1, 0),         // prev
      "ArrowRight": () => Math.min(focusedIndex+1, max),      // next
    }

    switch (code) {
      case "Escape": 
        document.getElementById(escapeId).focus();
        break;
      case "ArrowDown":
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowRight":
        event.preventDefault() // prevent screen movement
        document.getElementById(buttonKeyId(buttonKey, arrowTargets[code]())).focus()
        break;
    }
  }

  return (
    <div ref={containerRef} onKeyDown={keyDownHandler} 
    style={{
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(2em, ${maxContent})`,
      position: 'relative',
      gap: '2px',
    }}>
      {children}
    </div>
  )
}

export default ArrowNavigationGrid;