import PropTypes from 'prop-types';
import { getUser } from '../../store/selectors/user';
import { FormattedMessage } from 'react-intl';
import getMessage from '../../utils/getMessage';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { localizedNotifyError, localizedNotifySuccess } from '../../utils/notify';
import api from '../../api';
import * as Actions from '../../store/actions';

const HearingJoinRequestInfo = ({ hearing, user, listId, fetchAllHearings }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onJoinRequest = async () => {
    setIsLoading(true);
    const data = {
      hearing_id: hearing.id,
    };
    const result = await api
      .post({}, '/v1/hearing-join-request/', data);
    setIsLoading(false);
    if (result.status !== 201) {
      localizedNotifyError('sendHearingJoinRequestFailed');
    } else {
      localizedNotifySuccess('sendHearingJoinRequestSuccess');
      fetchAllHearings(listId);
    }
  };
  
  const joinRequestOption =
    <div className="hearing-join-request-button-wrap">
      {hearing.user_has_active_join_request
        ? <FormattedMessage id="hearingJoinRequestIsPending" />
        : (<button type='button' className='digi-button' onClick={onJoinRequest} disabled={isLoading}>
        {getMessage('sendHearingJoinRequest')}
      </button>)
      }
    </div>;
  const showJoinRequestInfo = !!user && !hearing.can_access_detail && !hearing.closed;
  return (<>
    {showJoinRequestInfo && joinRequestOption}
  </>);
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

HearingJoinRequestInfo.propTypes = {
  hearing: PropTypes.object.isRequired,
  user: PropTypes.object,
  fetchAllHearings: PropTypes.func,
  listId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  fetchAllHearings: (list) => dispatch(Actions.fetchHearingList(list, '/v1/hearing', {})),
});

export default connect(mapStateToProps, mapDispatchToProps)(HearingJoinRequestInfo);


