export const COMMENT_STICKERS_PATH = "/assets/images/stickers/"
export const COMMENT_STICKERS = [
  {
    id: 'swan1',
    alt: {
      fi: 'Piirretty joutsen',
      sv: 'Dragen svan',
    },
    src: 'swan1.png',
  },
  {
    id: 'swan_query',
    alt: {
      fi: 'Kysymysmerkkejä ja piirretty joutsen pää kallellaan',
      sv: 'Frågetecken och dragen svan med lutande huvud',
    },
    src: 'swan_query.png',
  },
]