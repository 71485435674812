import updeep from 'updeep';
import { handleActions } from 'redux-actions';


const defaultState = {};

const setIsEditingComment = (state, { payload: { sectionId, commentId, value } }) => {
  const hasEditing = !!state[sectionId]?.editingIds;
  let updatedSection;

  if (hasEditing) {
    const editingUpdate = {
      ...state[sectionId].editing,
      [commentId]: value,
    };
    updatedSection = {
      ...state[sectionId],
      editing: editingUpdate,
      editingIds: state[sectionId].editingIds.includes(commentId)
        ? state[sectionId].editingIds
        : [...state[sectionId].editingIds, commentId],
    };
  } else {
    updatedSection = {
      ...state[sectionId],
      editingIds: [commentId],
      editing: {
        [commentId]: value,
      },
    };
  }

  return {
    ...state,
    [sectionId]: updatedSection,
  };
};

const removeIsEditingComment = (state, { payload: { sectionId, commentId } }) => {
  const hasEditing = !!state[sectionId] && state[sectionId]?.editingIds?.includes(commentId);
  if (!hasEditing) {
    return state;
  }
  const editingUpdate = Object.keys(state[sectionId].editing).filter(key =>
    Number(key) !== commentId).reduce((obj, key) => {
      obj[key] = state[sectionId].editing[key];
      return obj;
    }, {},
  );
  const updatedSection = {
    ...state[sectionId],
    editing: editingUpdate,
    editingIds: state[sectionId].editingIds.filter(id => id !== commentId),
  };
  return {
    ...state,
    [sectionId]: updatedSection,
  };
};

const setEditingNewSectionComment = (state, { payload: { sectionId, value } }) => {
  const hasEditing = !!state[sectionId];
  let updatedSection;

  if (hasEditing) {
    updatedSection = {
      ...state[sectionId],
      newSectionComment: value,
    };
  } else {
    updatedSection = {
      newSectionComment: value,
    };
  }
  return updeep(
    {
      [sectionId]: updatedSection,
    },
    state,
  );
};

const removeEditingNewSectionComment = (state, { payload: { sectionId } }) => {
  const hasEditing = !!state[sectionId] && state[sectionId].newSectionComment;
  if (!hasEditing) {
    return state;
  }
  const updatedSection = {
    ...state[sectionId],
    newSectionComment: null,
  };
  return updeep(
    {
      [sectionId]: updatedSection,
    },
    state,
  );
};

const setIsEditingCommentReplay = (state, { payload: { sectionId, commentId, value } }) => {
  const hasReplying = !!state[sectionId] && !!state[sectionId].replyingIds;
  let updatedSection;

  if (hasReplying) {
    const replyingUpdate = {
      ...state[sectionId].replying,
      [commentId]: value,
    };
    updatedSection = {
      ...state[sectionId],
      replying: replyingUpdate,
      replyingIds: state[sectionId].replyingIds.includes(commentId)
        ? state[sectionId].replyingIds
        : state[sectionId].replyingIds.concat(commentId),
    };
  } else {
    updatedSection = {
      replyingIds: [commentId],
      replying: {
        [commentId]: value,
      },
    };
  }
  return updeep(
    {
      [sectionId]: updatedSection,
    },
    state,
  );
};

const removeEditingCommentReplay = (state, { payload: { sectionId, commentId } }) => {
  const hasReplying = !!state[sectionId] && state[sectionId].replyingIds.includes(commentId);
  if (!hasReplying) {
    return state;
  }
  const replyingUpdate = Object.keys(state[sectionId].replying).filter(key =>
    Number(key) !== commentId).reduce((obj, key) => {
      obj[key] = state[sectionId].replying[key];
      return obj;
    }, {},
  );
  const updatedSection = {
    ...state[sectionId],
    replying: replyingUpdate,
    replyingIds: state[sectionId].replyingIds.filter(id => id !== commentId),
  };
  return {
    ...state,
    [sectionId]: updatedSection,
  };
};

const updateEditingState = (state, { payload: { sectionId } }) => {
  const hasEditingSectionComment = !!state[sectionId]?.newSectionComment?.length;
  const hasReplyingIds = !!state[sectionId]?.replyingIds?.length;
  const hasEditingIds = !!state[sectionId]?.editingIds?.length;
  const hasAnyEditingState = hasEditingSectionComment || hasReplyingIds || hasEditingIds;

  const updatedSection = {
    ...state[sectionId],
    hasAnyEditing: hasAnyEditingState,
  };
  return {
    ...state,
    [sectionId]: updatedSection,
  };
};

const cleanState = (state) => {
  return defaultState;
};

export default handleActions(
  {
    setIsEditingComment,
    removeIsEditingComment,
    setEditingNewSectionComment,
    removeEditingNewSectionComment,
    setIsEditingCommentReplay,
    removeEditingCommentReplay,
    updateEditingState,
    cleanState,
  },
  defaultState,
);
