import { getUser } from '../../store/selectors/user';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box, Table, TableBody, TableCell, TableRow, Tabs } from '@mui/material';
import getMessage from '../../utils/getMessage';
import React, { cloneElement, useEffect, useState } from 'react';
import { generateRandomString } from '../../utils/common';
import { TabContext, TabPanel } from '@mui/lab';
import DigiraatiTabButton from './DigiraatiTabButton';
import useUserSummaryPanel from './useUserSummaryPanel.hook';
import { fetchInitialHearingList, fetchMoreHearings } from '../../store/actions';
import { hearingShape } from '../../types';

const StyledCell = styled(TableCell)`
    font: normal normal normal 16px PT Sans;
`;


const TableWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledTabs = styled(Tabs)`
    .MuiTabs-flexContainer {

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
`;

export const TabStyle = {
  width: '25%',
  '@media (max-width: 1100px)': {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },

};

const UserSummaryPanel = ({ user, fetchOwnHearingList, hearingLists }) => {

  const { tabs, params, getTabListByTabValue, isVotedCommentsLoading } = useUserSummaryPanel();
  const [value, setValue] = useState(tabs(user)[0].tabValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchOwnHearingList(params);
  }, []);

  useEffect(() => {
    setIsLoading(hearingLists.ownHearings.isFetching);
  }, [hearingLists.ownHearings.isFetching]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ paddingLeft: '0', paddingRight: '0' }}>
        <TableWrapperDiv>
          <Table
            sx={{
              minWidth: 320,
              tableLayout: 'fixed',
              width: '50%',
              '@media (max-width: 600px)': {
                width: '100%',
              },
            }}
            aria-label={getMessage('userInfoSummary')}
          >
            <TableBody>
              {tabs(user).map(item =>
                <TableRow
                  key={`summary_row_${generateRandomString()}`}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <StyledCell>{item.name}</StyledCell>
                  <StyledCell>{item.value}</StyledCell>
                </TableRow>,
              )}
            </TableBody>
          </Table>
        </TableWrapperDiv>
      </div>
      <div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
              <StyledTabs
                value={value}
                aria-label={getMessage('userInfoSummary')}
                TabIndicatorProps={{
                  style: {
                    visibility: 'hidden',
                  },
                }}
              >
                {tabs(user).map(tab =>
                  <DigiraatiTabButton
                    tabLabel={getMessage(tab.name)}
                    isSelected={value === tab.tabValue}
                    key={`tab_${tab.tabValue}`}
                    value={tab.tabValue}
                    style={TabStyle}
                    handleChange={handleChange} />,
                )}
              </StyledTabs>
            </Box>
            {tabs(user).map(tab =>
              <TabPanel
                key={`tab_panel_${tab.tabValue}`}
                value={tab.tabValue}
                style={{ padding: '0' }}
              >
                {cloneElement(tab.component, {
                  value: tab.tabValue,
                  index: tab.tabValue,
                  list: getTabListByTabValue(tab.tabValue, hearingLists?.ownHearings?.data),
                  handleReachBottom: fetchMoreHearings,
                  isLoading: tab.tabValue === 'votedSectionComments' ? isVotedCommentsLoading : isLoading,
                })}
              </TabPanel>,
            )}
          </TabContext>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  hearingLists: state.hearingLists,
});
const mapDispatchToProps = dispatch => ({
  fetchOwnHearingList: (params) =>
    dispatch(fetchInitialHearingList('ownHearings', '/v1/hearing/', params)),
  fetchMoreHearings: () => dispatch(fetchMoreHearings('ownHearings')),
});

UserSummaryPanel.propTypes = {
  user: PropTypes.object,
  fetchOwnHearingList: PropTypes.func,
  hearingLists: PropTypes.objectOf(
    PropTypes.shape({
      isFetching: PropTypes.bool,
      data: PropTypes.arrayOf(hearingShape),
    }),
  ),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserSummaryPanel));