import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

const TimeoutModal = ({ isOpen, close, onExtendSession }) =>
  <Modal className="delete-modal" show={isOpen} onHide={() => close()} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title><FormattedMessage id="tokenExpirationWarning"/></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <FormattedMessage id="tokenExpirationExplanation"/>
      </p>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="digi-button btn btn-default" onClick={() => { onExtendSession(); close(); }}>
        <FormattedMessage id="tokenRenew"/>
      </button>
    </Modal.Footer>
  </Modal>;

TimeoutModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  onDeleteComment: PropTypes.func
};

export default TimeoutModal;
