import React, { useState } from "react";
import { EMOJI_CATEGORIES, getEmojiList } from "../data/emoji";
import ArrowNavigationGrid from "./ArrowNavigationGrid";
import { Button, ControlLabel } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { buttonKeyId } from "../utils/emojiUtils";

const EmojiSelector = ({selectionHandler}) => {
  const [category, setCategory] = useState(EMOJI_CATEGORIES[0]);
  const [modifier, setModifier] = useState(null);

  const emojiArray = getEmojiList(category, modifier)
  const emojiCount = emojiArray.length

  const selectCategory = (selectionIndex) => {
    setCategory(EMOJI_CATEGORIES[selectionIndex])
    const resetModifier = EMOJI_CATEGORIES[selectionIndex].modifiers 
      ? EMOJI_CATEGORIES[selectionIndex].modifiers[0] 
      : null;
    setModifier(resetModifier)
  }
  
  const selectEmoji = (emoji) => {
    selectionHandler(emoji)
  }

  const selectedTab = (predicate) => {
    return predicate
      ? 'selected'
      : null
  }

  const NULL_MODIFIER_SYMBOL = '-'

  return (
    <div className="emoji-input">
        <ControlLabel>
          <FormattedMessage id="emojiAddToComment" />
        </ControlLabel>
          <div>
            { EMOJI_CATEGORIES.map( (ec, i) => (
              <Button key={ec.id} 
                onClick={() => selectCategory(i)}
                className={selectedTab(ec == category)}
              >
                <FormattedMessage id={ec.id} />
              </Button>
            ) ) }
          </div>
          {
            category.modifiers && ( // Make buttons for emoji modifiers if the category has them
              <div>
                <FormattedMessage id="emojiModifiers" />
                { category.modifiers.map( mod => (
                  <Button key={`modifier-${mod}`} 
                  onClick={() => setModifier(mod)}
                  className={selectedTab(mod == modifier)}
                  >
                    { mod ? String.fromCodePoint( parseInt(mod, 16) ) : NULL_MODIFIER_SYMBOL }
                  </Button>
                )) }
              </div>
            )
          }
      <ArrowNavigationGrid buttonKey='emoji' escapeId='button-emojis' max={emojiCount-1}>
        { emojiArray.map( (emoji, i) => (
          <Button key={`emoji-${emoji}`} 
            id={buttonKeyId('emoji',i)}
            className='emoji'
            onClick={() => {selectEmoji(emoji)}}
          >
            {emoji}
          </Button>
        ))}
      </ArrowNavigationGrid>


    </div>
  )

}

export default EmojiSelector;