import updeep from 'updeep';
import { handleActions } from 'redux-actions';

const receiveSectionComments = (state, { payload: { sectionId, data } }) => {
  // we must accept flattened as well as paginated comment listings
  let combinedResults = [];
  let count = 0;
  let next = null;
  if (Array.isArray(data)) {
    combinedResults = data;
    count = data.length;
  } else {
    combinedResults = state[sectionId]
      ? [...state[sectionId].results, ...data.results]
      : [];
    count = data.count;
    next = data.next;
  }
  // if ('results' in data) {
  //   combinedResults = state[sectionId] ? [...state[sectionId].results, ...data.results] : [];
  // } else {
  //   combinedResults = data;
  // }
  return updeep(
    {
      [sectionId]: {
        isFetching: false,
        fetchTime: new Date(),
        results: combinedResults,
        count,
        next,
      },
    },
    state,
  );
};

const postedComment = (state, { payload: { sectionId, jumpTo } }) => {
  // whenever we post, we want the newly posted comment displayed first and results reloaded
  return updeep(
    {
      [sectionId]: {
        jumpTo,
        results: [],
        ordering: '-created_at',
      },
    },
    state,
  );
};

/**
 * When comment is edited, no need to fetch the entire list again.
 * Update the object in array.
 */
const editedComment = (state, { payload: { sectionId, sectionCommentId, commentData } }) => {

  const updatedSection = {
    ...state[sectionId],
    results: [
      ...state[sectionId].results.map(result => {
        if (result.id === sectionCommentId) {
          return { ...updatePropertiesById(commentData.id, result, { ...commentData }) };
        }
        return result;
      }),
    ],
  };

  return updeep(
    {
      [sectionId]: updatedSection,
    },
    state,
  );

};

const postedCommentVote = (
  state,
  { payload: { commentId, sectionId, sectionCommentId, n_votes} },
) => {
  // the vote went through
    const updatedSection = {
      ...state[sectionId],
      results: [
        ...state[sectionId].results.map(result => {
          if (result.id === sectionCommentId) {
            return { ...updatePropertiesById(commentId, result, { n_votes: n_votes }) };
          }
          return result;
        }),
      ],
    };
    return updeep(
      {
        [sectionId]: updatedSection,
      },
      state,
    );
};

const beginFetchSectionComments = (
  state,
  { payload: { sectionId, ordering, cleanFetch } },
) => {
  if (
    state[sectionId] &&
    state[sectionId].ordering === ordering &&
    !cleanFetch
  ) {
    return updeep(
      {
        [sectionId]: {
          isFetching: true,
        },
      },
      state,
    );
  }
  return updeep(
    {
      [sectionId]: {
        isFetching: true,
        results: [],
        ordering,
      },
    },
    state,
  );
};

/**
 * Begin fetching the sub comments.
 * Show loading spinner on the parent comment description.
 */
const beginFetchSubComments = (
  state,
  { payload: { sectionId, sectionCommentId, commentId } },
) => {
  const updatedSection = {
    ...state[sectionId],
    results: [
      ...state[sectionId].results.map(result => {
        if (result.id === sectionCommentId) {
          return { ...updatePropertiesById(commentId, result, { loadingSubComments: true }) };
        }
        return result;
      }),
    ],
    jumpTo: commentId,
  };
  return updeep(
    {
      [sectionId]: updatedSection,
    },
    state,
  );
};

/**
 * Once comments are fetched, update the store with sub comments.
 */
const subCommentsFetched = (
  state,
  { payload: { sectionId, sectionCommentId, commentId, data, jumpTo } },
) => {
  const updatedSection = {
    ...state[sectionId],
    jumpTo: jumpTo,
    results: [
      ...state[sectionId].results.map(result => {
        if (result.id === sectionCommentId) {
          return {
            ...updatePropertiesById(commentId, result, {
              subComments: data.results,
              loadingSubComments: false,
            }),
          };
        }
        return result;
      }),
    ],
  };
  return updeep(
    {
      [sectionId]: updatedSection,
    },
    state,
  );
};

const setJumpTo = (state, { payload: { sectionId, jumpTo } }) => {

  return updeep(
    {
      [sectionId]: {
        jumpTo,
      },
    },
    state,
  );
};

const cleanJumpTo = (state, { payload: { sectionId } }) => {

  return updeep(
    {
      [sectionId]: {
        jumpTo: null,
      },
    },
    state,
  );
};

/*
* Function find subcomment with id=commentId and update all properties from propertyValueObj
* propertyValueObj ={
* key1: value,
* key2: function(data=>{})
* }
* propertyValueObj's value can be as value or as a function
* If value is function, this function call with data as a parameter
* Return updated data
* */
const updatePropertiesById = (commentId, data, propertyValueObj) => {
  if (data.id == commentId) {
    Object.keys(propertyValueObj).forEach(property => {
      if (propertyValueObj[property] instanceof Function) {
        data[property] = propertyValueObj[property](data);
      } else {
        data[property] = propertyValueObj[property];
      }
    });
    return data;
  }
  if (data.subComments !== undefined && data.subComments.length > 0) {
    for (let i = 0; i < data.subComments.length; i++) {
      data.subComments[i] = updatePropertiesById(commentId, data.subComments[i], propertyValueObj);
    }
  }
  return data;
};

export default handleActions(
  {
    beginFetchSectionComments,
    beginFetchSubComments,
    editedComment,
    postedComment,
    postedCommentVote,
    receiveSectionComments,
    subCommentsFetched,
    setJumpTo,
    cleanJumpTo,
  },
  {},
);
