import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Table, TableBody, TableRow } from '@mui/material';
import { generateRandomString } from '../../utils/common';
import { StyledCommentCell, TableWrapperDiv } from './UserVotedCommentListInterfaces';
import { withRouter } from 'react-router-dom';
import LoadSpinner from '../LoadSpinner';
import HearingListItem from '../Hearing/HearingListItem';
import Comment from '../Hearing/sortable-comment-list/comment-list/comment/Comment';

const UserVotedCommentListPanel = ({
                                     list = [],
                                     language,
                                     history,
                                     isLoading,
                                   }) => {
  const emptyFunc = () => undefined;

  useEffect(() => {
    // Remove link elements from focus cycle
    const votedCommentCellList = document.getElementsByClassName('voted-comment-cell');
    for (let i = 0; i < votedCommentCellList.length; i++) {
      const linkLists = votedCommentCellList[i].getElementsByTagName('a');
      for (let j = 0; j < linkLists.length; j++) {
        linkLists[j].setAttribute('tabIndex', '-1');
      }
    }
    const commentVoteActionLinks = document.getElementsByClassName('hearing-comment-vote-link');
    for (let i = 0; i < commentVoteActionLinks.length; i++) {
      commentVoteActionLinks[i].setAttribute('tabIndex', '-1');
    }
  }, []);

  const getList = async (comment) => {
    const slug = comment.hearing.slug;
    history.push({
      pathname: `/${slug}/comments`,
      search: `?lang=${language}&focusedComment=${comment.id}`,
    });
  };


  const onKeyPressHandler = async (event, comment) => {
    const pressedEnter = event.key === 'Enter';
    if (pressedEnter) {
      await getList(comment);
    }
  };

  const getHearingId = (comment) => comment.hearing.id;

  return (
    <div>
      <TableWrapperDiv tabIndex="-1">
        {isLoading
          ? <LoadSpinner />
          : (
            <Table
              sx={{
                tableLayout: 'fixed',
              }}
              aria-label="Voted comments table"
              tabIndex="-1"
            >
              <TableBody>
                {list.map((comment) =>
                  <TableRow
                    key={`table_row_${generateRandomString()}`}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <StyledCommentCell
                      onClick={() => getList(comment)}
                      onKeyDown={(e) => onKeyPressHandler(e, comment)}
                      tabIndex="0"
                      className="voted-comment-cell"
                    >
                      <ul className="commentlist">
                        <Comment
                          canReply={false}
                          canVote={false}
                          data={comment}
                          defaultNickname={'defaultNickname'}
                          hearingId={getHearingId(comment)}
                          key={comment.id + Math.random()}
                          jumpTo={false}
                          nicknamePlaceholder="nicknamePlaceholder"
                          onDeleteComment={emptyFunc}
                          onPostReply={emptyFunc}
                          onPostVote={emptyFunc}
                          questions={[]}
                          section={comment.section}
                          sectionCommentId={comment.id}
                          hearing={comment.hearing}
                          id={`comment-${comment.id}`}
                        />
                      </ul>
                      <HearingListItem hearing={comment.hearing} key={comment.hearing.id}
                                       language={language} />
                    </StyledCommentCell>
                  </TableRow>,
                )}
              </TableBody>
            </Table>)}
      </TableWrapperDiv>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  language: state.language,
});

UserVotedCommentListPanel.propTypes = {
  language: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default withRouter(connect(mapStateToProps)(UserVotedCommentListPanel));