import getMessage from '../../utils/getMessage';
import React, { useEffect, useState } from 'react';
import UserSummaryHearingListPanel from './UserSummaryHearingListPanel';
import UserVotedCommentListPanel from './UserVotedCommentListPanel';
import { fetchUserVotedComments } from '../../service/votedCommentsService';

const useUserSummaryPanel = () => {
  const params = { include: 'geojson', own_hearings: 'True' };
  const [votedComments, setVotedComments] = useState([]);
  const [isVotedCommentsLoading, setIsVotedCommentsLoading] = useState(false);

  const tabs = (user) => [
    {
      name: getMessage('userFollowingHearing'),
      value: user?.followedHearings.length ?? '0',
      component: <UserSummaryHearingListPanel />,
      tabValue: 'following',
    },
    {
      name: getMessage('userFacilitatorInHearing'),
      value: user?.facilitatorHearings.length ?? '0',
      component: <UserSummaryHearingListPanel />,
      tabValue: 'facilitator',
    },
    {
      name: getMessage('userMemberInHearing'),
      value: user?.membersHearings.length ?? '0',
      component: <UserSummaryHearingListPanel />,
      tabValue: 'member',
    },
    {
      name: getMessage('userVotedSectionComments'),
      value: user?.votedSectionComments.length ?? '0',
      component: <UserVotedCommentListPanel />,
      tabValue: 'votedSectionComments',
    },
  ];

  useEffect(() => {
    (async () => {
      setIsVotedCommentsLoading(true)
      setVotedComments(await fetchUserVotedComments());
      setIsVotedCommentsLoading(false)
    })();
  }, []);

  const getTabListByTabValue = (tabValue, ownHearingsList = []) => {
    switch (tabValue) {
      case 'following':
        return ownHearingsList.filter(currentHearing => currentHearing.user_is_follower);
      case 'facilitator':
        return ownHearingsList.filter(currentHearing => currentHearing.user_is_facilitator);
      case 'member':
        return ownHearingsList.filter(currentHearing => currentHearing.user_is_member);
      default:
        return votedComments;
    }
  };

  return {
    tabs,
    params,
    getTabListByTabValue,
    isVotedCommentsLoading,
  };
};

export default useUserSummaryPanel;