import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icon from '../../utils/Icon';
import Link from '../LinkWithLang';
import MouseOnlyLink from '../MouseOnlyLink';
import config from '../../config';
import getAttr from '../../utils/getAttr';
import { getHearingMainImageURL, getHearingURL } from '../../utils/hearing';
// import person from "../../assets/images/person.svg";
import HearingJoinRequestInfo from './HearingJoinRequestInfo';
import nl2br from 'react-nl2br';

// eslint-disable-next-line import/no-unresolved
import defaultImage from '@city-images/default-image.svg';
import getMessage from '../../utils/getMessage';
import HearingCardLabels from './HearingCardLabels';
import { Badge, IconButton } from '@mui/material';
import styled from 'styled-components';
import HearingCardInformation from './HearingCardInformation';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    font: 'normal normal normal 16px PT Sans',
  },
}));

const HearingCard = ({ hearing, language, className = '', history }) => {
  const backgroundImage = getHearingMainImageURL(hearing);
  const cardImageStyle = {
    backgroundImage: backgroundImage
      ? `url(${backgroundImage})`
      : `url(${defaultImage})`,
  };

  // FIXME: Should there be direct linking to hearing using certain language?
  const translationAvailable = !!getAttr(hearing.title, language, {
    exact: true,
  });
  const availableInLanguageMessages = {
    fi: 'Kuuleminen saatavilla suomeksi',
    sv: 'Hörandet tillgängligt på svenska',
    en: 'Questionnaire available in English',
  };
  
  
  function getFinalStatementPreviewURL() {
    const finalStatementPreviewURL =
      '/statement/preview/' + hearing.final_statement.id;
    return finalStatementPreviewURL;
  }

  return (
    <div className={`hearing-card ${className}`}>
      {hearing.can_access_detail ? (
        <MouseOnlyLink
          className="hearing-card-image"
          style={cardImageStyle}
          history={history}
          url={getHearingURL(hearing)}
          altText={getAttr(
            hearing.main_image && hearing.main_image.caption[language]
              ? hearing.main_image.caption[language]
              : hearing.title,
            language,
          )}
        />
      ) : (
        <div className="hearing-card-image" style={cardImageStyle} />
      )}
      <div className="hearing-card-content">
        <h3 className="h4 hearing-card-title" title={getAttr(hearing.title, language)}>
          {hearing.can_access_detail ? (
            <Link to={{ path: getHearingURL(hearing) }}>
              {getAttr(hearing.title, language)}
            </Link>
          ) : (
            getAttr(hearing.title, language)
          )}
        </h3>
        <p className="hearing-organization">{hearing.organization}</p>
        <p className='hearing-card-abstract'>{nl2br(getAttr(hearing.abstract, language))}</p>
        {!translationAvailable && (
          <div className="hearing-card-notice">
            <Icon name="exclamation-circle" aria-hidden="true" />
            <FormattedMessage id="hearingTranslationNotAvailable" />
            {config.languages.map(lang => {
              return getAttr(hearing.title, lang, { exact: true }) ? (
                <div className="language-available-message" key={lang} lang={lang}>
                  {availableInLanguageMessages[lang]}
                </div>
              ) : null;
            })}
          </div>
        )}
        
      </div>
      <HearingCardLabels hearing={hearing} language={language} />
      <HearingCardInformation hearing={hearing} history={history} />
      {hearing.final_statement && hearing.final_statement.published && (
          <a
            href={getFinalStatementPreviewURL()}
            target="_blank"
            rel="noopener noreferrer"
            className="final-statement-preview-link"
          >
            {getMessage('finalStatementPreview')}
          </a>
        )}
      <div style={{textAlign:'center', padding: '1em', paddingTop: '0', height: '4em'}}>
        
        <div>
          {hearing.can_access_detail ? (
            <FormattedMessage id="isMemberOfHearing" />
          ) : (
            <>
              <FormattedMessage id="isNotMemberOfHearing" />
            </>
          )}
        </div>
        <HearingJoinRequestInfo hearing={hearing} listId="openHearings" />
      </div>
    </div>
  );
};

HearingCard.propTypes = {
  className: PropTypes.string,
  hearing: PropTypes.object,
  language: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(HearingCard);
